<h1 mat-dialog-title>
  Project already exists
  <i style="color: orange" class="fa-solid fa-circle-exclamation"></i>
</h1>
<div mat-dialog-content>
  <p class="main-text">
    The project({{ data.currentScpName }}) you are trying to add to this
    {{
      data.currentProjectType === 'SUPPLY_CHAIN' ? 'CodeCertify' : 'AICertify'
    }}
    project is already linked to the following CodeCertify/AICertify projects:
  </p>

  <div
    class="main-text"
    style="display: flex; align-items: center"
    *ngFor="let scp of data.scpModuleData; let i = index"
    [mat-dialog-close]="{ action: 'navigate', index: i }"
  >
    <i
      class="fa-solid fa-rectangle-history"
      style="margin-right: 6px; color: #9da3a7; margin-top: -3px"
    ></i>
    <b class="link">{{ scp.scpProjectName }} </b>
  </div>

  <p class="main-text">
    Do you want to add the project(module) to this
    {{
      data.currentProjectType === 'SUPPLY_CHAIN' ? 'CodeCertify' : 'AICertify'
    }}
    project as well?
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button class="cancel" (click)="onNoClick()">No, cancel</button>
  <button mat-button [mat-dialog-close]="'scan'">Yes, add module</button>
</div>
