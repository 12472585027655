import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { License, LicenseCategories, LicenseCategoryPair } from '@app/models';
import { Observable, OperatorFunction } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { LicenseService } from '@app/services/license.service';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-component-add-dialog',
  templateUrl: './component-add-dialog.component.html',
  styleUrls: ['./component-add-dialog.component.scss'],
  providers: [DatePipe],
})
export class ComponentAddDialogComponent {
  public componentToAdd = {
    licenses: [],
    purlType: 'none',
    namespace: '',
    name: '',
    version: '',
    copyright: '',
    releaseDate: null,
    isInternal: false,
    scanAssetId: '',
    scanId: '',
    originalScanAssetId: '',
  };
  public minDate: NgbDateStruct = { year: 1990, month: 1, day: 1 }; // Minimum year
  public maxDate: NgbDateStruct = { year: 2100, month: 12, day: 31 }; // Maximum year

  public editing: boolean = false;

  @ViewChild('versionModel') versionModel!: NgModel;
  @ViewChild('nameModel') nameModel!: NgModel;
  @ViewChild('namespaceModel') namespaceModel!: NgModel;
  @ViewChild('dateModel') dateModel!: NgModel;
  @ViewChild('purlTypeModel') purlTypeModel!: NgModel;

  public purTypes = [
    { id: 'maven', name: 'MAVEN' },
    { id: 'npm', name: 'NPM' },
    { id: 'nuget', name: 'NUGET' },
    { id: 'pypi', name: 'PYPI' },
    { id: 'gem', name: 'GEM' },
    { id: 'composer', name: 'COMPOSER' },
    { id: 'conan', name: 'CONAN' },
    { id: 'golang', name: 'GOLANG' },
    { id: 'cocoapods', name: 'COCOAPODS' },
    { id: 'luarocks', name: 'LUAROCKS' },
    { id: 'bazel', name: 'BAZEL' },
    { id: 'hex', name: 'HEX' },
    { id: 'cargo', name: 'CARGO' },
    { id: 'cran', name: 'CRAN' },
    { id: 'github', name: 'GITHUB' },
    { id: 'gitlab', name: 'GITLAB' },
    { id: 'bitbucket', name: 'BITBUCKET' },
    { id: 'alpm', name: 'ALPM' },
    { id: 'apk', name: 'APK' },
    { id: 'bitnami', name: 'BITNAMI' },
    { id: 'conda', name: 'CONDA' },
    { id: 'deb', name: 'DEB' },
    { id: 'docker', name: 'DOCKER' },
    { id: 'generic', name: 'GENERIC' },
    { id: 'hackage', name: 'HACKAGE' },
    { id: 'mlflow', name: 'MLFLOW' },
    { id: 'oci', name: 'OCI' },
    { id: 'pub', name: 'PUB' },
    { id: 'qpkg', name: 'QPKG' },
    { id: 'rpm', name: 'RPM' },
    { id: 'swid', name: 'SWID' },
    { id: 'swift', name: 'SWIFT' },
    { id: 'unknown', name: 'UNKNOWN' },
    { id: 'none', name: 'NON_PURL' },
  ];

  @Output() public componentData = new EventEmitter();

  // operational
  public categories: LicenseCategoryPair[] = LicenseCategories.values();

  @ViewChild(UiModalComponent)
  modal: UiModalComponent;

  public constructor(
    private licenseService: LicenseService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {}

  public openDialog(componentData?): void {
    if (componentData) {
      this.editing = true;
      this.componentToAdd = {
        licenses: componentData.licenses,
        purlType: componentData.purlType,
        namespace: componentData.namespace,
        name: componentData.name,
        version: componentData.version,
        copyright: componentData.copyright,
        releaseDate: this.toNgbDateStruct(componentData.releaseDate),
        isInternal: componentData.isInternal,
        scanAssetId: '',
        scanId: '',
        originalScanAssetId: componentData.originalScanAssetId,
      };
    } else {
      this.resetFields();
      this.componentToAdd = {
        licenses: [],
        purlType: 'none',
        namespace: '',
        name: '',
        version: '',
        copyright: '',
        releaseDate: null,
        isInternal: false,
        scanAssetId: '',
        scanId: '',
        originalScanAssetId: '',
      };
      this.cdr.detectChanges();
    }
    this.modal.show();
  }

  public saveComponent(): void {
    if (this.validateAllFields()) {
      return;
    }

    if (this.componentToAdd.releaseDate?.year) {
      this.formatDate(this.componentToAdd.releaseDate);
    }

    const requestComponentData = {
      copyright: this.componentToAdd.copyright,
      licenseIds: this.componentToAdd.licenses.map(
        (license) => license.licenseId
      ),
      name: this.componentToAdd.name,
      namespace: this.componentToAdd.namespace,
      purlType: this.componentToAdd.purlType,
      releaseDate: this.componentToAdd.releaseDate,
      version: this.componentToAdd.version,
      isInternal: this.componentToAdd.isInternal,
      originalScanAssetId: this.componentToAdd.originalScanAssetId,
    };

    this.componentData.emit(requestComponentData);
    this.modal.hide();
  }

  public formatDate(date: NgbDateStruct): void {
    if (date) {
      // Convert NgbDateStruct to JavaScript Date
      const jsDate = new Date(date.year, date.month - 1, date.day); // month is 0-indexed
      // Format the JavaScript Date to dd-MM-yyyy
      this.componentToAdd.releaseDate =
        this.datePipe.transform(jsDate, 'dd-MM-yyyy') || '';
    }
  }

  public toNgbDateStruct(dateString: string): NgbDateStruct | null {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      // Return null if the input is not a valid date
      return null;
    }

    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1, // Months are 0-based in JavaScript
      day: date.getUTCDate(),
    };
  }

  formatter = (license: License) => license.name;

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.licenseService
          .getLicenseByName(term)
          .pipe(
            map((data) =>
              data.data.licensesByNameFilter
                .filter(
                  (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
                )
                .slice(0, 20)
            )
          )
      )
    );

  public setLicenseProperties(event) {
    this.componentToAdd.licenses.push(event.item);
  }

  public deleteLicense(id: string): void {
    this.componentToAdd.licenses = this.componentToAdd.licenses.filter(
      (license) => license.licenseId !== id
    );
  }

  private resetFields(): void {
    const controls = [
      this.versionModel,
      this.nameModel,
      this.namespaceModel,
      this.dateModel,
      this.purlTypeModel,
    ];

    controls.forEach((control) => {
      if (control && control.control) {
        control.control.markAsPristine(); // Mark as pristine
        control.control.markAsUntouched(); // Mark as untouched
        control.control.updateValueAndValidity(); // Re-evaluate validation state
      }
    });
  }

  private validateAllFields(): boolean {
    const missingFields: string[] = [];
    const spaceNotAllowedMessage = 'Component Name cannot contain spaces';

    // Check if name is missing or contains spaces
    if (!this.componentToAdd.name) {
      this.nameModel.control.markAsTouched();
      missingFields.push('Component Name');
    } else if (/\s/.test(this.componentToAdd.name)) {
      this.nameModel.control.markAsTouched();
      missingFields.push(spaceNotAllowedMessage);
    }

    if (!this.componentToAdd.version) {
      this.versionModel.control.markAsTouched();
      missingFields.push('Component Version');
    }

    if (!this.componentToAdd.namespace) {
      this.namespaceModel.control.markAsTouched();
      missingFields.push('Component Namespace');
    }

    if (!this.componentToAdd.purlType) {
      this.purlTypeModel.control.markAsTouched();
      missingFields.push('PURL Type');
    }
    if (missingFields.length > 0) {
      return true;
    }

    return false;
  }
}

import { Injectable } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  private readonly DELIMITER = '-';

  // Parse the string to NgbDateStruct
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const parts = value.split(this.DELIMITER);
      return {
        day: parseInt(parts[0], 10),
        month: parseInt(parts[1], 10),
        year: parseInt(parts[2], 10),
      };
    }
    return null;
  }

  // Format the NgbDateStruct to string
  format(date: NgbDateStruct | null): string {
    return date
      ? `${this.pad(date.day)}${this.DELIMITER}${this.pad(date.month)}${
          this.DELIMITER
        }${date.year}`
      : '';
  }

  // Pad single-digit numbers with a leading zero
  private pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
