import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  License,
  LicenseCategories,
  LicenseCategory,
  LicenseCategoryPair,
  LicenseData,
} from '@app/models';
import { Observable, OperatorFunction } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { LicenseService } from '@app/services/license.service';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-license-add-dialog',
  templateUrl: './license-add-dialog.component.html',
  styleUrls: ['./license-add-dialog.component.scss'],
})
export class LicenseAddDialogComponent implements OnInit {
  // target objects
  licensedObject: any;
  licenseData: LicenseData;

  // save function
  @Input() saveFn: (licensedObject: any, licenseData: LicenseData) => void;

  // input values
  public licenseId: string = '';
  public licenseName: string = '';
  public licenseCategory: LicenseCategory;
  public licenseText: string = '';
  public isLicenseEdit: boolean = false;

  // operational
  public categories: LicenseCategoryPair[] = LicenseCategories.values();
  public typeaheadModel: any; // for license search typeahead (todo: ref: looks like this
  // model and property [(ngModel)] of typeahead input are not required, remove them)

  @ViewChild(UiModalComponent)
  modal: UiModalComponent;

  public constructor(private licenseService: LicenseService) {}

  ngOnInit(): void {}

  public openDialog(licensedObject: any) {
    this.licenseId = '';
    this.licenseName = '';
    this.licenseText = '';
    this.licenseCategory = null;
    this.typeaheadModel = null;
    this.licensedObject = licensedObject;
    this.modal.show();
  }

  public saveLicense(licenseTextModel, licenseCategoryModel): void {
    if (!this.licenseText || !this.licenseCategory) {
      licenseTextModel.control.markAsTouched();
      licenseCategoryModel.control.markAsTouched();
      return;
    }
    this.licenseData = new LicenseData(
      this.licenseId,
      this.licenseName,
      this.licenseText,
      this.licenseCategory
    );
    this.saveFn(this.licensedObject, this.licenseData);
    this.modal.hide();
  }

  // license search, typeahead -------------------------------------------------------------------------------------------------
  formatter = (license: License) => license.name;

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.licenseService
          .getLicenseByName(term)
          .pipe(
            map((data) =>
              data.data.licensesByNameFilter
                .filter(
                  (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
                )
                .slice(0, 20)
            )
          )
      )
    );

  setLicenseProperties(event) {
    this.licenseId = event.item.licenseId;
    this.licenseName = event.item.name;
    this.licenseText = event.item.body;
    this.licenseCategory = event.item.category;
  }
}
