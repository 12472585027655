<!--
<div class="modal-body">
    <button type="button" *ngIf="statusMessageFromback == 'COMPLETE'" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    <div class="scan-started-blk" *ngIf="!isMessageButtonClick && !isError" style="display: block;">
        <i class="scan-icon"><img src="assets/images/scan-icon.png" alt=""></i>
        <p *ngIf="!isMessageButtonClick && !isError" class="loader">{{message}}</p>
        <button class="btn btn-primary scan-btn" *ngIf="!isMessageButtonClick && !isError"
            (click)="gotItBtn();isMessageButtonClick = !isMessageButtonClick">
            Got It!</button>
    </div>

    <div class="scan-started-blk" *ngIf="isMessageButtonClick && !isError">
        <i class="scan-icon" *ngIf="statusMessageFromback == '' || !statusMessageFromback"><img src="assets/images/scan-icon.png" alt=""></i>
        <p *ngIf="statusMessageFromback == '' || !statusMessageFromback">{{message}}</p>

        <i class="loader-img" *ngIf="statusMessageFromback == 'RUNNING'">
            <img src="assets/images/scan-loader.gif" alt="">
        </i>
        <p *ngIf="statusMessageFromback == 'RUNNING'">{{projectName}} scans in Progress.</p>

        <i class="scan-icon far fa-thumbs-up status-success" *ngIf="statusMessageFromback == 'COMPLETE'">
        </i>
        <p *ngIf="statusMessageFromback == 'COMPLETE'">
            <span>Scan of {{projectName}} is completed. Take a look at <a (click)="goToProjectPage()">results</a></span>
        </p>
    </div>

    <div class="scan-started-blk" *ngIf="isError == true">
        <i class="scan-icon fas fa-exclamation-circle status-error">
        </i>
        <p *ngIf="isError">{{statusMessageFromback}}</p>
        <button class="btn btn-primary" (click)="closeModel()">Close</button>
    </div>

</div> -->

<div class="modal-body">
  <perfect-scrollbar style="max-height: calc(100vh - 100px)">
    <div *ngFor="let item of filter(recentlyScanCompleted)">
      <div class="scan-modal-blk scan-success-blk">
        <div class="scan-started-blk">
          <div class="scan-progress">
            <i class="scan-icon far fa-thumbs-up status-success loader-img"></i>

            <div class="scan-progress-rt" *ngIf="!!item.projectId">
              <p>
                {{ item.projectName }} scan is complete.
                <a class="cursor-pointer" (click)="goToProject(item)"
                  ><u>View results</u></a
                >
                &nbsp;<i class="fas fa-external-link-alt"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="scan-modal-blk scan-success-blk scan-failure-blk"
      *ngFor="let item of filterErrorPro(errorScanProject)"
    >
      <div class="scan-started-blk">
        <div class="scan-progress">
          <i
            class="scan-icon fas fa-exclamation-circle status-failure loader-img"
          ></i>
          <div class="scan-progress-rt">
            <p>Something went wrong in {{ item.projectName }} project!</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="scan-modal-blk"
      *ngIf="projectScanResults.length || recentlyScanCompleted.length"
    >
      <div class="scan-started-blk">
        <div
          class="scan-main-blk"
          [ngClass]="{
            'single-project': projectScanResults.length === 1,
            'scan-main-blk-wrapper': projectScanResults.length === 1
          }"
          *ngIf="projectScanResults.length >= 1"
        >
          <ngb-accordion
            #acc="ngbAccordion"
            (panelChange)="beforeChange($event)"
          >
            <ngb-panel id="preventchange-2" [ngClass]="'custom-panel'">
              <ng-template ngbPanelTitle>
                <div class="scan-progress">
                  <i class="scan-icon loader-img"
                    ><img src="assets/images/scan-loader.gif" alt=""
                  /></i>
                  <div class="scan-progress-rt">
                    <p>
                      <span
                        *ngIf="projectScanResults.length === 1; else inprogress"
                        >{{ projectScanResults[0].projectName }} project scan in
                        progress</span
                      >
                      <ng-template #inprogress>
                        {{ projectScanResults.length }} projects scan in
                        progress
                      </ng-template>
                      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </p>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div
                  class="scan-progress"
                  style="cursor: default"
                  *ngFor="let project of projectScanResults"
                >
                  <div class="scan-progress-rt">
                    <p>
                      {{ project.projectName }} project scan.
                      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </p>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <div
          class="scan-main-blk recently-completed-scan"
          *ngIf="recentlyScanCompleted.length >= 1"
        >
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModel()"
            *ngIf="projectScanResults.length == 0"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h6>Recently Completed Scans</h6>

          <ul>
            <li *ngFor="let project of filterUniqRecords()">
              <a
                style="color: #4680ff !important"
                *ngIf="!!project.projectId"
                (click)="goToProject(project)"
              >
                <u>{{ project.projectName }}</u>
              </a>
              project. <i class="fas fa-external-link-alt"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>
