import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'purlParser',
})
export class PurlParserPipe implements PipeTransform {
  transform(
    purl: string,
    field: 'purlType' | 'namespace' | 'name' | 'version'
  ): string | null {
    if (!purl || !field) {
      return null;
    }

    const purlRegex = /^pkg:([^/]+)\/([^/]+)\/([^@]+)@(.+)$/;
    const match = purl.match(purlRegex);

    if (!match) {
      return null; // Invalid PURL format
    }

    const [, purlType, namespace, name, version] = match;

    const fields: Record<string, string> = {
      purlType,
      namespace,
      name,
      version,
    };

    return fields[field] || null;
  }
}
