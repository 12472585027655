<i
  [matTooltip]="stylesClass === 'core-components' ? 'Policy Violations' : ''"
  container="body"
  class="fa-duotone fa-solid fa-file-circle-exclamation"
  [class]="stylesClass"
  [ngbPopover]="popContent"
  [autoClose]="true"
  popoverTitle="Policy Violations"
  triggers="manual"
  #p="ngbPopover"
  popoverClass="policy-popover"
  (click)="$event.stopPropagation(); p.open()"
></i>

<ng-template #popContent>
  <i (click)="closePopover()" class="fa-solid fa-xmark" aria-label="Close"></i>
  <div *ngFor="let policy of policies" class="policy-name-wrapper">
    <i class="fa-solid fa-feather-pointed" style="margin-right: 6px"></i>
    <span class="policy-name no-cut" (click)="goToPolicy(policy.policyId)">
      {{ policy.policyName }}
    </span>
  </div>
</ng-template>
