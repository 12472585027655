import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe supports filtering an array of objects based on multiple fields
 */
@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(
    value: Array<any>,
    fields: Array<string>,
    searchTerm: string
  ): Array<any> {
    if (!searchTerm) {
      return value; // If no search term, return the original list
    }

    // Filter the list of items where any of the specified fields contain the search term
    return value.filter((item) =>
      fields.some((field: string) =>
        item[field]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }
}
