import { Injectable } from '@angular/core';
import {
  ComponentVersions,
  ComponentVersionsQuery,
  ComponentVersionsResult,
  ComponentWithVersions,
  ComponentWithVersionsDetailQuery,
  Copyright,
  License,
  ScanComponentWithVersionsQuery,
  TxComponent,
  VulnerabilityEdgeInterface,
} from '@app/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  FixVersionsDialog,
  LoadComponentDetails,
  LoadNextComponentVersions,
  LoadPatchedVersions,
} from './component-detail.actions';
import { ScanComponentService } from '@app/services/scan-component.service';
import { map, switchMap, take } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';
import { ProjectBreadcrumbsService } from '@app/services/core';
import { PatchedInfoSimplified } from '@app/threat-center/shared/models/types';
import { FixService } from '@app/services/fix.service';
import { of } from 'rxjs';

export class ComponentDetailStateModel {
  component: TxComponent;
  componentId: string;
  group: string;
  licensesList: Array<{ node: License }>;
  name: string;
  releaseDate: string;
  version: string;
  vulnerabilityDetails: Array<VulnerabilityEdgeInterface>;
  breadcrumbDetail: any;
  patchedVersions: PatchedInfoSimplified;
  vulnerabilitiesSeveretyTotal: {
    CRITICAL: number;
    HIGH: number;
    MEDIUM: number;
    LOW: number;
    INFO: number;
    UNASSIGNED: number;
  };
  copyrightList: Array<Copyright>;
  componentVersions: ComponentVersions;
  componentVersionsLoading: boolean;
  loading: boolean;
  versionToFix: string;
}

type LocalStateContext = StateContext<ComponentDetailStateModel>;

export const DefaultVulnerabilitiesSeveretyTotal = {
  CRITICAL: 0,
  HIGH: 0,
  MEDIUM: 0,
  LOW: 0,
  INFO: 0,
  UNASSIGNED: 0,
};

export const vulnerabilitiesSortOrder = [
  'CRITICAL',
  'HIGH',
  'MEDIUM',
  'LOW',
  'INFO',
  'UNASSIGNED',
];

export const DefaultState = {
  component: null,
  componentId: '',
  group: '',
  licensesList: [],
  name: '',
  releaseDate: '',
  version: '',
  vulnerabilityDetails: [],
  breadcrumbDetail: {},
  patchedVersions: null,
  vulnerabilitiesSeveretyTotal: DefaultVulnerabilitiesSeveretyTotal,
  copyrightList: [],
  componentVersions: {
    resultList: [],
    pageSize: 0,
    start: 0,
    total: 0,
    currentindex: -1,
  },
  componentVersionsLoading: false,
  loading: false,
  versionToFix: '',
};

@State<ComponentDetailStateModel>({
  name: 'componentDetail',
  defaults: DefaultState,
})
@Injectable()
export class ComponentDetailState {
  public constructor(
    private scanComponentService: ScanComponentService,
    private projectBreadcrumbsService: ProjectBreadcrumbsService,
    private fixService: FixService,
    private store: Store
  ) {}

  @Selector()
  static component(
    componentDetailState: ComponentDetailStateModel
  ): TxComponent {
    return componentDetailState.component;
  }

  @Selector()
  static componentVersions(
    componentDetailState: ComponentDetailStateModel
  ): Array<ComponentVersionsResult> {
    return componentDetailState.componentVersions
      ? componentDetailState.componentVersions.resultList
      : [];
  }

  @Selector()
  static currentComponentVersionIndex(
    componentDetailState: ComponentDetailStateModel
  ): number {
    return componentDetailState.componentVersions
      ? componentDetailState.componentVersions.currentIndex
      : 0;
  }

  @Selector()
  static vulnerabilities(
    componentDetailState: ComponentDetailStateModel
  ): Array<VulnerabilityEdgeInterface> {
    return componentDetailState.vulnerabilityDetails;
  }

  @Selector()
  static severetiesTotal(componentDetailState: ComponentDetailStateModel): {
    CRITICAL: number;
    HIGH: number;
    MEDIUM: number;
    LOW: number;
    INFO: number;
    UNASSIGNED: number;
  } {
    return componentDetailState.vulnerabilitiesSeveretyTotal;
  }

  @Selector()
  static licenses(
    componentDetailState: ComponentDetailStateModel
  ): Array<{ node: License }> {
    return componentDetailState.licensesList;
  }

  @Selector()
  static loading(componentDetailState: ComponentDetailStateModel): boolean {
    return componentDetailState.loading;
  }

  @Selector()
  static copyrights(
    componentDetailState: ComponentDetailStateModel
  ): Array<Copyright> {
    return componentDetailState.copyrightList;
  }

  @Selector()
  static breadcrumbs(componentDetailState: ComponentDetailStateModel): any {
    return componentDetailState.breadcrumbDetail;
  }

  @Selector()
  static patchedVersions(
    componentDetailState: ComponentDetailStateModel
  ): PatchedInfoSimplified {
    return componentDetailState.patchedVersions;
  }

  @Selector()
  static versionToFix(componentDetailState: ComponentDetailStateModel): string {
    return componentDetailState.versionToFix;
  }

  @Action(LoadComponentDetails)
  protected loadComponentDetails(
    ctx: LocalStateContext,
    action: {
      componentId: string;
      scanId: string;
      projectId: string;
      isComposite: boolean;
      defaultPageSize: number;
    }
  ): void {
    const state: ComponentDetailStateModel = ctx.getState();
    ctx.patchState({ loading: true });
    this.scanComponentService
      .getScanComponentWithVersions(
        action.scanId,
        action.componentId,
        action.projectId,
        action.isComposite,
        action.defaultPageSize
      )
      .pipe(
        map(
          (result: ApolloQueryResult<ScanComponentWithVersionsQuery>) =>
            result.data.scanComponent
        ),
        switchMap((res) => of(res)),
        take(1)
      )
      .subscribe((res: ComponentWithVersionsDetailQuery) => {
        if (this.projectBreadcrumbsService.getProjectBreadcrumb()) {
          this.projectBreadcrumbsService.settingProjectBreadcrumb(
            'Component',
            res.name,
            res.componentId,
            false
          );
        }

        const vulnerabilitiesSeveretyTotal = {
          ...DefaultVulnerabilitiesSeveretyTotal,
        };
        if (res.component && res.component['vulnerabilities']) {
          res.component['vulnerabilities'].edges.forEach((element) => {
            if (element.node?.severity) {
              vulnerabilitiesSeveretyTotal[element.node.severity] += 1;
            }
          });
        }
        const component = {
          name: null,
          group: null,
          version: null,
          releaseDate: null,
          componentId: null,
        } as ComponentWithVersions;
        if (res.component) {
          res.component['supplyChainRiskScore'] = res[
            'supplyChainRiskScore'
          ] as any;
          res.component['componentType'] = res[
            'componentType'
          ] as any;
          res.component['releaseDate'] = res.releaseDate as any;

          res.component['maxSeverity'] = this.getMaxSeverety(
            res.component['vulnerabilities']
          );

          let currentIndex = -1;
          res.component.componentVersions.resultList.forEach(
            (componentVersion, index) => {
              componentVersion.vulnerabilities.maxSeverity =
                this.getMaxSeverety(componentVersion.vulnerabilities);
              componentVersion.vulnerabilitiesSeveretyTotal = {
                ...DefaultVulnerabilitiesSeveretyTotal,
              };
              componentVersion.vulnerabilities.edges.forEach((element) => {
                componentVersion.vulnerabilitiesSeveretyTotal[
                  element.node.severity
                ] += 1;
              });
              componentVersion.changeIcon = this.calcucateChangeIcon(
                res.component,
                componentVersion,
                vulnerabilitiesSeveretyTotal
              );
              if (componentVersion.changeIcon === 'current') {
                currentIndex = index;
              }
            }
          );
          res.component.componentVersions.currentIndex = currentIndex;
        } else {
          component.name = res.name;
          component.group = res.group;
          component.version = res.version;
          component.releaseDate = res.releaseDate as any;
          component.componentId = res.componentId;
          component.componentType = res.componentType;
          component['supplyChainRiskScore'] = res[
            'supplyChainRiskScore'
          ] as any;
        }

        ctx.setState({
          ...state,
          component: res.component || component,
          componentVersions: res.component
            ? res.component.componentVersions
            : null,
          licensesList: this.removeDuplicates(res.licenses.edges),
          vulnerabilitiesSeveretyTotal,
          vulnerabilityDetails: res.component
            ? res.component['vulnerabilities'].edges
            : [],
          breadcrumbDetail:
            this.projectBreadcrumbsService.getProjectBreadcrumb(),
          copyrightList: res.component ? res.component.copyrightList : [],
        });
        this.store.dispatch(new LoadPatchedVersions(action.componentId));
      });
  }

  @Action(LoadNextComponentVersions)
  protected loadNextComponentVersions(ctx: LocalStateContext) {
    const componentVersions: ComponentVersions = {
      ...ctx.getState().componentVersions,
    };
    const componentId = ctx.getState().component.componentId;
    const start: number = componentVersions.start + componentVersions.pageSize;
    if (
      start > componentVersions.total ||
      ctx.getState().componentVersionsLoading
    ) {
      return;
    }
    ctx.patchState({ componentVersionsLoading: true });
    this.scanComponentService
      .getComponentVersions(componentId, componentVersions.pageSize, start)
      .pipe(
        map(
          (result: ApolloQueryResult<ComponentVersionsQuery>) =>
            result.data.componentVersions
        )
      )
      .subscribe((res) => {
        const state = ctx.getState();
        componentVersions.pageSize = res.pageSize;
        componentVersions.total = res.total;
        componentVersions.start = res.start;
        res.resultList.forEach((componentVersion, index) => {
          componentVersion.vulnerabilities.maxSeverity = this.getMaxSeverety(
            componentVersion.vulnerabilities
          );
          componentVersion.vulnerabilitiesSeveretyTotal = {
            ...DefaultVulnerabilitiesSeveretyTotal,
          };
          componentVersion.vulnerabilities.edges.forEach((element) => {
            componentVersion.vulnerabilitiesSeveretyTotal[
              element.node.severity
            ] += 1;
          });
          componentVersion.changeIcon = this.calcucateChangeIcon(
            state.component,
            componentVersion,
            state.vulnerabilitiesSeveretyTotal
          );
          if (componentVersion.changeIcon === 'current') {
            componentVersions.currentIndex = index + res.start;
          }
        });
        componentVersions.resultList = [
          ...componentVersions.resultList,
          ...res.resultList,
        ];

        ctx.patchState({
          componentVersions,
          componentVersionsLoading: false,
        });
        ctx.patchState({
          loading: false,
        });
      });
  }
  @Action(FixVersionsDialog)
  protected fixVersionsDialog(
    ctx: LocalStateContext,
    action: {
      versionToFix: string;
    }
  ): void {
    ctx.patchState({
      versionToFix: action.versionToFix,
    });
  }

  @Action(LoadPatchedVersions)
  protected loadPatchedVersions(
    ctx: LocalStateContext,
    action: {
      componentId: string;
    }
  ): void {
    const state: ComponentDetailStateModel = ctx.getState();

    this.fixService
      .getPatchedVersion(action.componentId)
      .pipe(take(1))
      .subscribe((patchedVersion: PatchedInfoSimplified) => {
        ctx.patchState({
          patchedVersions: patchedVersion,
        });
        ctx.patchState({
          loading: false,
        });
      });
  }

  private removeDuplicates(licenses: Array<{ node: License }>) {
    const unique = licenses.filter(
      (license, index, selfLicenses) =>
        index ===
        selfLicenses.findIndex(
          (self) =>
            self.node.name === license.node.name &&
            self.node.category === license.node.category
        )
    );
    return unique;
  }

  private getMaxSeverety(vulnerabilities: {
    edges: Array<{
      node: {
        vulnerabilityAlias: string;
        severity: string;
      };
    }>;
  }): string {
    if (vulnerabilities.edges.length === 0) {
      return null;
    }
    if (vulnerabilities.edges.length === 1) {
      return vulnerabilities.edges[0].node.severity;
    }

    const severities = vulnerabilities.edges.map((edge) => edge.node.severity);
    let index: number;
    severities.forEach((item: string) => {
      if (index === undefined) {
        index = vulnerabilitiesSortOrder.indexOf(item);
      } else {
        const itIndex = vulnerabilitiesSortOrder.indexOf(item);
        index = index < itIndex ? index : itIndex;
      }
    });
    return vulnerabilitiesSortOrder[index];
  }

  private calcucateChangeIcon(
    component,
    componentVersion: ComponentVersionsResult,
    vulnerabilitiesSeveretyTotal
  ): string {
    if (component.version === componentVersion.version) {
      return 'current';
    }
    const componentMaxSeverety: string = this.getMaxSeverety(
      component.vulnerabilities
    );
    if (componentMaxSeverety && componentVersion.vulnerabilities.maxSeverity) {
      const componentMaxSeveretyIndex: number =
        vulnerabilitiesSortOrder.indexOf(componentMaxSeverety);
      const componentVersionMaxSeveretyIndex: number =
        vulnerabilitiesSortOrder.indexOf(
          componentVersion.vulnerabilities.maxSeverity
        );
      if (componentMaxSeveretyIndex < componentVersionMaxSeveretyIndex) {
        return 'lower';
      }
      if (componentMaxSeveretyIndex > componentVersionMaxSeveretyIndex) {
        return 'higher';
      }
      if (componentMaxSeveretyIndex === componentVersionMaxSeveretyIndex) {
        const componentSeveretiesCount =
          vulnerabilitiesSeveretyTotal[
            vulnerabilitiesSortOrder[componentMaxSeveretyIndex]
          ];
        const componentVersionSeveretiesCount =
          componentVersion.vulnerabilitiesSeveretyTotal[
            vulnerabilitiesSortOrder[componentVersionMaxSeveretyIndex]
          ];
        if (componentSeveretiesCount === componentVersionSeveretiesCount) {
          for (
            let i = componentMaxSeveretyIndex;
            i < vulnerabilitiesSortOrder.length;
            i++
          ) {
            const componentSeveretiesCountNext: number =
              vulnerabilitiesSeveretyTotal[vulnerabilitiesSortOrder[i]];
            const componentVersionSeveretiesCount: number =
              componentVersion.vulnerabilitiesSeveretyTotal[
                vulnerabilitiesSortOrder[i]
              ];
            if (
              componentSeveretiesCountNext !== componentVersionSeveretiesCount
            ) {
              return componentSeveretiesCountNext >
                componentVersionSeveretiesCount
                ? 'lower'
                : 'higher';
            }
          }
          return 'equal';
        }
        return componentSeveretiesCount > componentVersionSeveretiesCount
          ? 'lower'
          : 'higher';
      }
    }
    if (componentMaxSeverety && !componentVersion.vulnerabilities.maxSeverity) {
      return 'lower';
    }
    return 'equal';
  }
}
