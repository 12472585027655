import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlreadyScannedScpModule } from '@app/models';

@Component({
  selector: 'app-already-attached-modal',
  templateUrl: './already-attached-modal.component.html',
  styleUrls: ['./already-attached-modal.component.scss'],
})
export class AlreadyAttachedModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AlreadyAttachedModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      scpModuleData: Array<AlreadyScannedScpModule>;
      currentScpName: string;
      currentProjectType: string;
    }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
