<div class="modal-header">
  <h3 class="modal-title" id="modal-basic-title">Vulnerabilities</h3>
</div>
<div class="vulnerabilites-data">
  <ul>
    <li
      *ngFor="let vulData of vulnerabilities"
      class="data-item {{ vulData?.severity }}"
    >
      <div class="row">
        <div class="col-sm-9 col-xs-12">
          <div class="vulner-data-lt">
            <div
              (click)="gotoDetails(vulData?.vulnerabilityId)"
              class="cursor-pointer"
            >
              <div class="data-tag-ttl">
                <h3>
                  <span class="badge m-r-5 badge-vuln-large-{{ vulData?.severity }}"
                  >{{ vulData?.severity }}</span>

                  <span class="badge m-r-5 badge-light-primary">{{
                    vulData?.source
                  }}</span>
                </h3>
                <span class="data-code ml-2">
                  {{ vulData?.vulnerabilityAlias }}</span
                >
                <p class="data-hdng">
                  {{ getComponentName() }}
                </p>
              </div>
              <div class="data-row">
                <h5>
                  <label
                    *ngIf="!!vulData?.cwe?.cweId"
                    class="data-tag purple-tag mr-2"
                    >CWE-{{ vulData?.cwe?.cweId }}</label
                  >
                </h5>
                <p>{{ vulData?.cwe?.name }}</p>
              </div>

              <div class="data-row">
                <div class="info-cols">
                  <small>CVSS2:</small> {{ vulData?.cvssV2BaseScore }}
                </div>

                <div class="info-cols">
                  <small>CVSS3:</small> {{ vulData?.cvssV3BaseScore }}
                </div>

                <div class="info-cols" *ngIf="vulData?.epss">
                  <small>EPSS:</small> {{ vulData?.epss?.epssPercent }}% ({{
                    vulData?.epss?.percentilePercent
                      | toFixed : 0 : direction.UP
                  }}th Percentile)
                </div>

                <div class="info-cols">
                  <small>Published:</small>{{ vulData?.published | date }}
                </div>

                <div *ngIf="vulData?.updated" class="info-cols">
                  <small>Updated:</small>{{ vulData?.updated | date }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-3 col-xs-12" *ngIf="secureVersions">
          <div class="vulner-data-rt">
            <div class="secure-versions-wrapper mt-2">
              <span class="secure-versions-title">Secure Versions</span>
              <span class="secure-versions-subtitle">
                Next Secure Version
              </span>
              <span class="secure-versions-version"
                >{{
                  secureVersions[vulData?.vulnerabilityAlias]
                    ?.nextPatchedVersion
                }}
              </span>
              <span class="secure-versions-subtitle">
                Latest Secure Version
              </span>
              <span class="secure-versions-version"
                >{{
                  secureVersions[vulData?.vulnerabilityAlias]
                    ?.latestPatchedVersion
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
