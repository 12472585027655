import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import { SimmQuery } from '@app/models';
import gql from 'graphql-tag';

@Injectable()
export class SimmService {
  constructor(private coreGraphQLService: CoreGraphQLService) {}

  compare(
    sourceContent: string,
    matchContent: string,
    scanAssetId: string,
    scanId: string
  ) {
    return this.coreGraphQLService.coreGQLReq<SimmQuery>(
      gql`
        query (
          $sourceContent: String
          $matchContent: String
          $scanId: UUID
          $scanAssetId: String
        ) {
          simmCompare(
            sourceContent: $sourceContent
            matchContent: $matchContent
            scanId: $scanId
            scanAssetId: $scanAssetId
          ) {
            leftStart
            leftEnd
            rightStart
            rightEnd
          }
        }
      `,
      'no-cache',
      { sourceContent, matchContent, scanAssetId, scanId }
    );
  }

  applyPatch(scanId: string) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation PatchAsset($scanId: UUID) {
          patchAsset(scanId: $scanId)
        }
      `,
      { scanId }
    );
  }
}
