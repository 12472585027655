import { Injectable } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';

import { CoreGraphQLService } from '@app/services/core/core-graphql.service';

import {
  AttributeAssetRequestInput,
  ComponentQuery,
  GetAssetContentQuery,
  LicenseQuery,
  ProjectQuery,
  Scan,
  ScanAssetQuery,
  ScanLicenseAssetConnection,
  ScanLicenseQuery,
  ScanQuery,
  VulnerabilityQuery,
} from '@app/models';
import { GraphQLPage } from '@app/models/paging';
import { UtilsService } from './utils.service';
import { ScanResult } from '@app/compliance-dashboard/common-models/project-audit.models';

@Injectable()
export class ProjectService {
  constructor(
    private coreGraphQLService: CoreGraphQLService,
    private utilsService: UtilsService
  ) {}

  archiveProject(projectId: string) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation ($projectId: UUID) {
          archiveProject(projectId: $projectId)
        }
      `,
      { projectId }
    );
  }

  getProjectBasicData(projectId: string) {
    return this.coreGraphQLService.coreGQLReq<ProjectQuery>(
      gql`
            query {
                project(projectId: "${projectId}") {
                    orgId
                    entityId
                    projectId
                    parentProjectId
                    name
                }
            }
          `,
      'no-cache'
    );
  }

  getMovingModule(projectId: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            query {
                project(projectId: "${projectId}") {
                    scpProjectId
                    projectId
                    movingModule
                }
            }
          `,
      'no-cache'
    );
  }

  getProjectWithMetrics(projectId: string) {
    return this.coreGraphQLService.coreGQLReq<ProjectQuery>(
      gql`
            query {
                project(projectId: "${projectId}") {
                    orgId
                    entityId
                    projectId
                    parentProjectId
                    name
            
                    projectMetricsGroup {
                        projectMetrics{
                            measureDate
                            vulnerabilityMetrics {
                                severityMetrics
                            }
                            assetMetrics {
                                assetCompositionMetrics
                            }
                            componentMetrics {
                                vulnerabilityMetrics
                                licenseCategoryMetrics
                                licenseFamilyMetrics
                                licenseNameMetrics
                            }
                            licenseMetrics {
                                licenseCategoryMetrics
                                licenseFamilyMetrics
                                licenseNameMetrics
                            }
                            supplyChainMetrics {
                                supplyChainMetrics
                            }
                        }
                    }
                }
            }
          `,
      'no-cache'
    );
  }

  getProject(
    projectId: string,
    filter: string,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined,
    isComposite: boolean = false
  ) {
    const filterArg = 'filterBranchName: "' + filter + '",';
    const firstArg = !!first ? `first: ${first}` : '';
    const lastArg = !!last ? `, last: ${last}` : '';
    const afterArg = after ? `, after: "${after}"` : '';
    const beforeArg = before ? `, before: "${before}"` : '';
    return this.coreGraphQLService.coreGQLReq<ProjectQuery>(
      gql`
            query {
              project(projectId: "${projectId}") {
                projectId
                parentProjectId
                entityId
                orgId
                name
                scans(${filterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                  }
                  totalCount
                  edges {
                    node {
                      scanId
                      projectId
                      branch
                      tag
                      version
                      versionHash
                      created
                      status
                      errorMsg
                      log,
                      components {
                        totalCount
                      }
                      scanMetricsSummary(isComposite: ${isComposite}) {
                        vulnerabilityMetrics {
                          critical,
                          high,
                          medium,
                          low,
                          info,
                          unassigned,
                        },
                        licenseMetrics {
                          copyleftStrong,
                           copyleftWeak,
                           copyleftPartial,
                           copyleftLimited,
                           copyleft,
                           custom,
                           dual,
                           permissive,
                        },
                        supplyChainMetrics {
                          risk
                          quality
                        }
                        assetMetrics {
                          embedded,
                          openSource,
                          unique
                        }
                      }
                    }
                  }
                }
                projectMetricsGroup {
                  projectMetrics{
                      measureDate
                      vulnerabilityMetrics {
                          severityMetrics
                      }
                      assetMetrics {
                          assetCompositionMetrics
                      }
                      componentMetrics {
                          vulnerabilityMetrics
                          licenseCategoryMetrics
                          licenseFamilyMetrics
                          licenseNameMetrics
                      }
                      licenseMetrics {
                          licenseCategoryMetrics
                          licenseFamilyMetrics
                          licenseNameMetrics
                      }
                      supplyChainMetrics {
                          supplyChainMetrics
                      }
                  }
                }
              }
            }
          `,
      'no-cache'
    );
  }

  getScanVulnerabilitiesByPage(
    scanId: string,
    filter?: string,
    graphQLPage?: GraphQLPage,
    isComposite: boolean = false
  ) {
    const { filterArg, firstArg, lastArg, afterArg, beforeArg } =
      this.utilsService.getAPIParameters(filter, graphQLPage);
    return this.coreGraphQLService.coreGQLReq<ScanResult>(
      gql`
        query {
          scan(scanId:"${scanId}") {
              scanId,
              isScmActionsAvailableForScan
              vulnerabilities(isComposite: ${isComposite}, ${filterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                pageInfo {
                   hasNextPage
                   hasPreviousPage
                   startCursor
                   endCursor
                 }
                 totalCount
                  edges {
                      node {
                          components {
                            edges {
                              node {
                                group,
                                name,
                                version,
                                componentId
                              }
                            }
                          }
                          vulnerabilityId,
                          vulnerabilityAlias,
                          source,
                          recommendation,
                          vulnerableVersions,
                          patchedVersions
                          published,
                          cwe{
                              cweId,
                              name
                          },
                          cvssV2BaseScore,
                          cvssV3BaseScore,
                          severity
                      }
                  }
              }
          }
        }
    `,
      'cache-first'
    );
  }

  getScanVulnerabilitiesForScpSummary(
    scanId: string,
    filter?: string,
    graphQLPage?: GraphQLPage,
    isComposite: boolean = false
  ) {
    const { filterArg, firstArg, lastArg, afterArg, beforeArg } =
      this.utilsService.getAPIParameters(filter, graphQLPage);
    return this.coreGraphQLService.coreGQLReq<ScanResult>(
      gql`
        query {
          scan(scanId:"${scanId}") {
              scanId,
              isScmActionsAvailableForScan
              vulnerabilities(isComposite: ${isComposite}, ${filterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                pageInfo {
                   hasNextPage
                   hasPreviousPage
                   startCursor
                   endCursor
                 }
                 totalCount
                  edges {
                      node {
                          
                          firstComponentForVuln {
                              group
                              name
                              version
                              componentId
                          }
                          
                          vulnerabilityId,
                          vulnerabilityAlias,
                          source,
                          recommendation,
                          vulnerableVersions,
                          patchedVersions
                          published,
                          cwe{
                              cweId,
                              name
                          },
                          cvssV2BaseScore,
                          cvssV3BaseScore,
                          severity
                      }
                  }
              }
          }
        }
    `,
      'cache-first'
    );
  }

  getScanVulnerabilities(
    scanId: string,
    filter: string,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined,
    isComposite: boolean = false
  ) {
    const vulnFilterArg: string = 'filter: "' + filter + '"';
    const firstArg: string = !!first ? `first: ${first}` : '';
    const lastArg: string = !!last ? `last: ${last}` : '';
    const afterArg: string = after ? `, after: "${after}"` : '';
    const beforeArg: string = before ? `, before: "${before}"` : '';
    return this.coreGraphQLService.coreGQLReq<Scan>(
      gql`
          query {
            scan(scanId:"${scanId}") {
                scanId,
                isScmActionsAvailableForScan
                vulnerabilities(isComposite: ${isComposite}, ${vulnFilterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                  pageInfo {
                     hasNextPage
                     hasPreviousPage
                     startCursor
                     endCursor
                   }
                   totalCount
                    edges {
                        node {
                            components {
                              edges {
                                node {
                                  group,
                                  name,
                                  version,
                                  componentId,
                                  componentType,
                                  componentDiscoveryMethod,
                                  workspaceRelativeFilePath,
                                  vulnLinkCorrect
                                }
                              }
                            },
                            epss {
                              date
                              epssPercent
                              percentilePercent
                            },
                            vulnerabilityId,
                            vulnerabilityAlias,
                            source,
                            recommendation,
                            vulnerableVersions,
                            patchedVersions
                            published,
                            cwe{
                                cweId,
                                name
                            },
                            cvssV2BaseScore,
                            cvssV3BaseScore,
                            severity
                        }
                    }
                }
            }
          }
      `,
      'cache-first'
    );
  }

  getScanComponents(
    scanId: string,
    filter: string,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined,
    isComposite: boolean = false
  ) {
    const compFilterArg = 'filter: "' + filter + '"';
    const firstArg = !!first ? `, first: ${first}` : '';
    const lastArg = !!last ? `, last: ${last}` : '';
    const afterArg = after ? `, after: "${after}"` : '';
    const beforeArg = before ? `, before: "${before}"` : '';
    return this.coreGraphQLService.coreGQLReq<ScanQuery>(
      gql`
              query {
                scan(scanId:"${scanId}") {
                  scanId,
                  isScmActionsAvailableForScan,
                  components(isComposite: ${isComposite}, ${compFilterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      startCursor
                      endCursor
                    }
                    totalCount
                    edges {
                      node {
                        componentId,
                        supplyChainRiskScore,
                        name,
                        group,
                        version,
                        isInternal,
                        componentType,
                        componentLocation,
                        componentDiscoveryMethod,
                        dependencyManagerType,
                        workspaceRelativeFilePath,
                        minDepth,
                        autofixPullRequest,
                        policyViolationNotation {
                          policyId
                          policyName
                        },
                        licenses {
                          edges {
                            node {
                              licenseId,
                              name,
                              category
                            }
                          }
                        }
                        vulnerabilities {
                          edges {
                            node {
                              vulnerabilityId,
                              vulnerabilityAlias,
                              severity,
                              patchedVersions,
                              cwe {
                                cweId,
                                name
                              },
                              epss {
                                epssPercent
                                date
                                percentilePercent
                              },
                              cvssV2BaseScore,
                              cvssV3BaseScore,
                            }
                          }
                        }
                        metrics {
                          critical,
                          high,
                          medium,
                          low,
                          unassigned,
                          vulnerabilities,
                          suppressed,
                          findingsTotal,
                          findingsAudited,
                          findingsUnaudited,
                          inheritedRiskScore,
                          firstOccurrence,
                          lastOccurrence
                        }
                      }
                    }
                  }
                }
              }
          `,
      'cache-first'
    );
  }

  getProjectComponentsForAudit(
    scanId: string,
    filter?: string,
    graphQLPage?: GraphQLPage
  ) {
    const { filterArg, firstArg, lastArg, afterArg, beforeArg } =
      this.utilsService.getAPIParameters(filter, graphQLPage);

    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
              query {
                scan(scanId:"${scanId}") {
                  isScmActionsAvailableForScan
                  components_audit(${filterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      startCursor
                      endCursor
                    }
                    totalCount
                    edges {
                      node {
                        scanId,
                        componentId,
                        projectPath,
                        name,
                        group,
                        version,
                        orgId,
                        purl,
                        isInternal,
                        componentType,
                        componentLocation,
                        componentDiscoveryMethod,
                        rejected,
                        reviewed,
                        isInternal,
                        auditorRecommends,
                        auditorFullName,
                        auditDate
                        licenses {
                          edges {
                            node {
                              licenseId
                              name
                              category
                              isOrgLicense
                              orgId
                              rejected
                              
                              license {
                                    licenseId
                                    name
                                    spdxId
                                    body
                                    description
                                    notes
                                    
                                    category
                                    style
                                    publicationYear
                                    type
                                    compatible
                                    incompatible
                                    isOrgLicense
                                    attributes {
                                        name
                                        type
                                        description
                                    }
                              }
                            }
                          }
                        }
                        vulnerabilities {
                          edges {
                            node {
                              vulnerabilityId,
                              vulnerabilityAlias,
                              severity,
                              patchedVersions,
                              cwe {
                                cweId
                                name
                              }
                              epss {
                                epssPercent
                                date
                                percentilePercent
                              }
                              cvssV2BaseScore,
                              cvssV3BaseScore,
                              published,
                              source
                            }
                          }
                        }
                        scanComponentCopyrights {
                          text
                          rejected
                        }
                      }
                    }
                  }
                }
              }
          `,
      'no-cache'
    );
  }

  getScanLicenses(
    scanId: string,
    filter: string,
    first?: number,
    last?: number,
    after?: string,
    before?: string,
    fetchPolicy?: WatchQueryFetchPolicy,
    isComposite?: boolean
  ) {
    const filterArg = `filter: "${filter}"`;
    const firstArg = first ? `first: ${first}` : '';
    const lastArg = last ? `last: ${last}` : '';
    const afterArg = after ? `, after: "${after}"` : '';
    const beforeArg = before ? `, before: "${before}"` : '';

    return this.coreGraphQLService.coreGQLReq<ScanQuery>(
      gql`query {
          scan(scanId:"${scanId}") {
            scanId,
            repositoryId,
            isScmActionsAvailableForScan,
            licenses(isComposite: ${
              isComposite !== undefined && isComposite
            }, ${filterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              totalCount
              edges {
                node {
                  licenseId,
                  spdxId
                  name,
                  category,
                  style,
                  type,
                  spdxId,
                  publicationYear,
                  isOsiApproved,
                  isFsfLibre,
                  licenseDiscovery,
                  requireAttribution,
                  attributionStatus,  
                  licenseOrigin,
                  trustLevel,
                  licenseOrigins,
                  licenseDiscoveries
                }
              }
            }
          }
        }`,
      fetchPolicy
    );
  }

  getScanLicensesByPage(
    scanId: string,
    fetchPolicy: FetchPolicy = 'cache-first',
    filter?: string,
    graphQLPage?: GraphQLPage,
    isComposite?: boolean
  ) {
    const { filterArg, firstArg, lastArg, afterArg, beforeArg } =
      this.utilsService.getAPIParameters(filter, graphQLPage);

    return this.coreGraphQLService.coreGQLReq<{
      licenses: ScanLicenseAssetConnection;
      scanId: string;
    }>(
      gql`query {
          scan(scanId:"${scanId}") {
            isScmActionsAvailableForScan,
            scanId,
            licenses(isComposite: ${
              isComposite !== undefined && isComposite
            }, ${filterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              totalCount
              edges {
                node {
                  licenseId,
                  spdxId
                  name,
                  category,
                  style,
                  type,
                  spdxId,
                  publicationYear,
                  isOsiApproved,
                  isFsfLibre,
                  licenseDiscovery,
                  licenseOrigin,
                  trustLevel,
                  licenseOrigins,
                  licenseDiscoveries
                }
              }
            }
          }
        }`,
      fetchPolicy
    );
  }

  getScanLicensesForSupply(
    scanId: string,
    fetchPolicy: FetchPolicy = 'cache-first'
  ) {
    return this.coreGraphQLService.coreGQLReq<{
      licenses: ScanLicenseAssetConnection;
      scanId: string;
    }>(
      gql`query {
          scan(scanId:"${scanId}") {
            isScmActionsAvailableForScan,
            scanId,
            licenseListForModule {
                  licenseId,
                  spdxId
                  name,
                  category,
                  style,
                  type,
                  spdxId,
                  publicationYear,
                  isOsiApproved,
                  isFsfLibre,
                  licenseDiscovery,
                  licenseOrigin,
                  trustLevel,
            }
          }
        }`,
      fetchPolicy
    );
  }

  // todo: ref: use optional params instead of initializing defaults to undefined
  // Refactored
  getComponentByPage(componentId: string, graphQLPage?: GraphQLPage) {
    // todo: ref: eliminate code duplications
    const { firstArg, lastArg, afterArg, beforeArg } =
      this.utilsService.getAPIParameters('', graphQLPage);
    return this.coreGraphQLService.coreGQLReq<ComponentQuery>(gql`
            query {
                component(componentPurl:"${componentId}") {
                    componentId,
                    name,
                    group,
                    version,
                    isInternal,
                    description
                    copyrightList {
                        text
                    }
                    licenses {
                        edges {
                            node {
                                licenseId,
                                name,
                                category,
                                style,
                                spdxId,
                                publicationYear
                            }
                        }
                    }
                    vulnerabilities(${firstArg}${lastArg}${afterArg}${beforeArg}) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        totalCount
                        edges {
                            node {
                                vulnerabilityId,
                                vulnerabilityAlias,
                                epss {
                                  date
                                  epssPercent
                                  percentilePercent
                                },
                                source,
                                recommendation,
                                vulnerableVersions,
                                patchedVersions
                                published,
                                cwe {
                                    cweId,
                                    name
                                },
                                cvssV2BaseScore,
                                cvssV3BaseScore,
                                severity
                            }
                        }
                    }
                    metrics {
                        critical,
                        high,
                        medium,
                        low,
                        unassigned,
                        vulnerabilities,
                        suppressed,
                        findingsTotal,
                        findingsAudited,
                        findingsUnaudited,
                        inheritedRiskScore,
                        firstOccurrence,
                        lastOccurrence
                    }
                }
            }
        `);
  }

  // todo: ref: use optional params instead of initializing defaults to undefined
  getComponent(
    componentId: string,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined
  ) {
    // todo: ref: eliminate code duplications
    const firstArg = !!first ? `first: ${first}` : '';
    const lastArg = !!last ? `last: ${last}` : '';
    const afterArg = after ? `, after: "${after}"` : '';
    const beforeArg = before ? `, before: "${before}"` : '';
    return this.coreGraphQLService.coreGQLReq<ComponentQuery>(gql`
           query {
              component(componentPurl:"${componentId}") {
                componentId,
                name,
                group,
                version,
                isInternal,
                description
                copyrightList {
                  text
                }
                licenses {
                  edges {
                    node {
                      licenseId,
                      name,
                      category,
                      style,
                      spdxId,
                      publicationYear
                    }
                  }
                }
                vulnerabilities(${firstArg}${lastArg}${afterArg}${beforeArg}) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                  }
                  totalCount
                  edges {
                    node {
                      vulnerabilityId,
                      vulnerabilityAlias,
                      epss {
                        epssPercent
                        date
                        percentilePercent
                      },
                      source,
                      recommendation,
                      vulnerableVersions,
                      patchedVersions
                      published,
                      cwe{
                         cweId,
                         name
                      },
                      cvssV2BaseScore,
                      cvssV3BaseScore,
                      severity
                    }
                  }
                }
                metrics {
                  critical,
                  high,
                  medium,
                  low,
                  unassigned,
                  vulnerabilities,
                  suppressed,
                  findingsTotal,
                  findingsAudited,
                  findingsUnaudited,
                  inheritedRiskScore,
                  firstOccurrence,
                  lastOccurrence
              }
            }
          }
        `);
  }

  getLicense(licenseId: string) {
    return this.coreGraphQLService.coreGQLReq<LicenseQuery>(gql`
            query {
               license(licenseId:"${licenseId}") {
                   licenseId,
                   name,
                   spdxId
                   body,
                   category,
                   style,
                   type,
                   publicationYear,
                   description,
                   isOsiApproved,
                   isFsfLibre,
                   isDeprecated,
                   attributes {
                     name,
                     type,
                     description
                   }
              }
           }
        `);
  }

  getLicenseAndLicenseComponent(
    licenseId: string,
    licenseDiscovery: string,
    licenseOrigin: string,
    scanId: string,
    projectId: string,
    isComposite = false,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined,
    parentScanAssetId: string = undefined,
    assetFilter: string = undefined
  ) {
    const parentAssetId: string =
      !!parentScanAssetId && parentScanAssetId.length > 0
        ? 'parentScanAssetId: "' + parentScanAssetId + '", '
        : '';
    const assetFilterArg: string =
      'filter: "' + (!!assetFilter ? assetFilter : '') + '"';
    const firstArg: string = !!first ? `, first: ${first}` : '';
    const lastArg: string = !!last ? `, last: ${last}` : '';
    const afterArg: string = after ? `, after: "${after}"` : '';
    const beforeArg: string = before ? `, before: "${before}"` : '';
    /* eslint-disable */
    return this.coreGraphQLService.coreGQLReq<ScanLicenseQuery>(gql`
          query {
             scanLicense(scanId:"${scanId}", licenseId:"${licenseId}", licenseDiscovery:"${licenseDiscovery}", licenseOrigin:"${licenseOrigin}", projectId: "${projectId}", isComposite: ${isComposite}) {
                 orgId,
                 licenseOrigin,
                 licenseDiscovery
                 license {
                     licenseId,
                     name,
                     spdxId
                     body,
                     category,
                     style,
                     type,
                     publicationYear,
                     description,
                     notes,
                     isOsiApproved,
                     isFsfLibre,
                     isDeprecated,
                     compatible,
                     incompatible,
                     attributes {
                       name,
                       type,
                       description
                     }
                }
                scanComponents(${firstArg}${lastArg}${afterArg}${beforeArg}, projectId: "${projectId}", isComposite: ${isComposite}) {
                  totalCount
                  edges {
                    node {
                      componentId
                    }
                  }
                }
                scanOpenSourceProject {
                  owner,
                  name,
                  repoWebsite
                },
                scanAssetsTree(${parentAssetId}${assetFilterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                  }
                  totalCount
                  edges {
                    node {
                      name,
                      size,
                      assetSize,
                      scanAssetId,
                      originAssetId
                      workspacePath
                      status,
                      scanAssetType,
                      parentScanAssetId,
                      attributionStatus,
                      matchType,
                      percentEmbedded,
                      averagePercentCopied,
                      matchCount,
                      matchScore,
                      scanId,
                      isSupportedLicenseAnnotation,
                      otCount,
                      projectId,
                      assetRepositoryUrl {
                        data
                      },
                      embeddedAssets {
                        edges {
                          node {
                            name,
                            scanAssetId,
                            assetMatchId,
                            percentMatch,
                            matchScore,
                            latestReleaseVersion,
                            earliestReleaseVersion,
                            assetRepositoryUrl {
                              data
                            },
                            assetSize
                            matchLicenses {
                            category,
                            name,
                            licenseFamilyName,
                            needIncludeInCode,
                            attributionStatus,
                            licenseId,
                            },
                            matchGroups {
                              edges {
                                node {
                                  name
                                  assetMatchId
                                  earliestReleaseVersion
                                  earliestReleaseDate
                                  latestReleaseDate
                                  latestReleaseVersion
                                  licensesByMatchGroup {
                                  category
                                  name
                                  }
                                }
                              }
                            }
                            matchRepository {
                              repositoryCode
                              repositoryOwner
                              repositoryName
                            }
                          },
                        }
                      }
                    }
                  }
                }
                scanLicenseAssets(first:1) {
                  edges {
                    node {
                      scanAssetId
                    }
                  }
                }
            }
         }
      `);
    /* eslint-enable */
  }

  getLicenseComponents(
    licenseId: string,
    licenseDiscovery: string,
    licenseOrigin: string,
    scanId: string,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined,
    filters?: string
  ) {
    const licenseFilterArg = 'filter: "' + filters + '"';
    const firstArg: string = !!first ? `, first: ${first}` : '';
    const lastArg: string = !!last ? `, last: ${last}` : '';
    const afterArg: string = after ? `, after: "${after}"` : '';
    const beforeArg: string = before ? `, before: "${before}"` : '';
    /* eslint-disable */
    return this.coreGraphQLService.coreGQLReq<ScanLicenseQuery>(gql`
          query {
            scanLicense(scanId:"${scanId}", licenseId:"${licenseId}", licenseDiscovery:"${licenseDiscovery}", licenseOrigin:"${licenseOrigin}") {
              licenseId,
              scanComponents(${firstArg}${lastArg}${afterArg}${beforeArg}, ${licenseFilterArg}) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
                totalCount
                edges {
                  node {
                    componentId,
                    name,
                    group,
                    version,
                    isInternal,
                    componentType,
                    componentLocation,
                    componentDiscoveryMethod,
                    supplyChainMainRiskScore,
                    supplyChainRiskScore,
                    minDepth,
                    vulnerabilities {
                      edges {
                        node {
                          vulnerabilityId,
                          vulnerabilityAlias,
                          severity,
                          patchedVersions
                        }
                      }
                    },
                    licenses {
                      edges {
                        node {
                          category
                          licenseDiscovery
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `);
    /* eslint-enable */
  }

  getScanLicenseAssets(
    licenseId: string,
    licenseDiscovery: string,
    licenseOrigin: string,
    scanId: string = null,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined,
    parentScanAssetId: string = undefined,
    assetFilter: string = undefined
  ) {
    const parentAssetId: string =
      !!parentScanAssetId && parentScanAssetId.length > 0
        ? 'parentScanAssetId: "' + parentScanAssetId + '", '
        : '';
    const assetFilterArg: string =
      'filter: "' + (!!assetFilter ? assetFilter : '') + '"';
    const firstArg: string = !!first ? `, first: ${first}` : '';
    const lastArg: string = !!last ? `, last: ${last}` : '';
    const afterArg: string = after ? `, after: "${after}"` : '';
    const beforeArg: string = before ? `, before: "${before}"` : '';
    /* eslint-disable */
    return this.coreGraphQLService.coreGQLReq<ScanLicenseQuery>(gql`
          query {
            scanLicense(scanId:"${scanId}", licenseId:"${licenseId}", licenseDiscovery:"${licenseDiscovery}", licenseOrigin:"${licenseOrigin}") {
              licenseId,
              scanAssetsTree(${parentAssetId}${assetFilterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
                totalCount
                edges {
                  node {
                    name,
                    size,
                    assetSize,
                    scanAssetId,
                    originAssetId
                    workspacePath
                    status,
                    scanAssetType,
                    parentScanAssetId,
                    attributionStatus,
                    matchType,
                    percentEmbedded,
                    averagePercentCopied,
                    matchCount
                    otCount
                  }
                }
              }
            }
          }
        `);
    /* eslint-enable */
  }

  getScanLicenseAssetsWithEmbedded(
    licenseId: string,
    licenseDiscovery: string,
    licenseOrigin: string,
    scanId: string = null,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined,
    parentScanAssetId: string = undefined,
    assetFilter: string = undefined
  ) {
    const parentAssetId: string =
      !!parentScanAssetId && parentScanAssetId.length > 0
        ? 'parentScanAssetId: "' + parentScanAssetId + '", '
        : '';
    const assetFilterArg: string =
      'filter: "' + (!!assetFilter ? assetFilter : '') + '"';
    const firstArg: string = !!first ? `, first: ${first}` : '';
    const lastArg: string = !!last ? `, last: ${last}` : '';
    const afterArg: string = after ? `, after: "${after}"` : '';
    const beforeArg: string = before ? `, before: "${before}"` : '';
    /* eslint-disable */
    return this.coreGraphQLService.coreGQLReq<ScanLicenseQuery>(gql`
          query {
            scanLicense(scanId:"${scanId}", licenseId:"${licenseId}", licenseDiscovery:"${licenseDiscovery}", licenseOrigin:"${licenseOrigin}") {
              licenseId,
              scanAssetsTree(${parentAssetId}${assetFilterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
                totalCount
                edges {
                  node {
                    name,
                    size,
                    assetSize,
                    scanAssetId,
                    originAssetId
                    workspacePath
                    status,
                    scanAssetType,
                    parentScanAssetId,
                    attributionStatus,
                    matchType,
                    percentEmbedded,
                    averagePercentCopied,
                    matchCount
                    matchScore
                    otCount
                    scanId,
                      isSupportedLicenseAnnotation,
                      otCount,
                      projectId,
                      assetRepositoryUrl {
                        data
                      },
                      embeddedAssets {
                        edges {
                          node {
                            name,
                            scanAssetId,
                            assetMatchId,
                            percentMatch,
                            matchScore,
                            latestReleaseVersion,
                            earliestReleaseVersion,
                            assetRepositoryUrl {
                              data
                            },
                            assetSize
                            matchLicenses {
                            category,
                            name,
                            licenseFamilyName,
                            needIncludeInCode,
                            attributionStatus,
                            licenseId,
                            },
                            matchGroups {
                              edges {
                                node {
                                  name
                                  assetMatchId
                                  earliestReleaseVersion
                                  earliestReleaseDate
                                  latestReleaseDate
                                  latestReleaseVersion
                                  licensesByMatchGroup {
                                  category
                                  name
                                  }
                                }
                              }
                            }
                            matchRepository {
                              repositoryCode
                              repositoryOwner
                              repositoryName
                            }
                          },
                        }
                      }
                  }
                }
              }
            }
          }
        `);
    /* eslint-enable */
  }

  getVulnerability(vulnerabilityId: string, orgId: string, scanId: string) {
    return this.coreGraphQLService.coreGQLReq<VulnerabilityQuery>(gql`
        query {
            vulnerability(vulnerabilityAlias:"${vulnerabilityId}") {
              vulnerabilityId,
              vulnerabilityAlias,
              created,
              source,
              description,
              references,
              published,
              updated,
              cwe {
                  cweId,
                  name
              }
              epss {
                epssPercent
                date
                percentilePercent
              }
              cvssV2BaseScore,
              cvssV2ImpactSubScore,
              cvssV2ExploitabilitySubScore,
              cvssV2Vector,
              cvssV3BaseScore,
              cvssV3ImpactSubScore,
              cvssV3ExploitabilitySubScore,
              cvssV3Vector,
              severity,
              recommendation,
              credits,
              vulnerableVersions,
              patchedVersions,
              title,
              subtitle,
              components(vulnerabilityAlias:"${vulnerabilityId}" orgId:"${orgId}" scanId:"${scanId}") {
                edges {
                  node {
                    componentId,
                    name,
                    version,
                    group
                  }
                }
              },
              vulnJiraTicket(vulnerabilityAlias:"${vulnerabilityId}" orgId:"${orgId}" scanId:"${scanId}") {
                id, key, self
              }
          }
        }
      `);
  }

  getScanRepository(scanId: string) {
    return this.coreGraphQLService.coreGQLReq<ScanQuery>(gql`
       query {
          scan(scanId:"${scanId}") {
            scanRepository {
              repositoryOwner,
              repositoryName,
              repositoryEndpointType,
              registrationId
            }
          }
        }
      `);
  }

  // todo: ref: move to ScanAssetService
  getScanAssets(
    scanId: string,
    parentScanAssetId: string,
    filter: string,
    graphQLPage: GraphQLPage,
    fetchPolicy?: FetchPolicy,
    isComposite: boolean = false
  ) {
    const { filterArg, firstArg, lastArg, afterArg, beforeArg } =
      this.utilsService.getAPIParameters(filter, graphQLPage);

    const parentId: string =
      parentScanAssetId.length > 0
        ? 'parentScanAssetId: "' + parentScanAssetId + '", '
        : '';

    fetchPolicy = fetchPolicy !== undefined ? fetchPolicy : 'no-cache';

    return this.coreGraphQLService.coreGQLReq<ScanQuery>(
      gql`
        query {
          scan(scanId:"${scanId}") {
            scanId,
            isScmActionsAvailableForScan,
            otMetaData
            scanOpenSourceProject {
              owner,
              name,
              repoWebsite
            },
            scanAssetsTree(isComposite: ${isComposite}, ${parentId}${filterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              totalCount
              edges {
                node {
                  orgId,
                  name,
                  size,
                  assetSize,
                  projectId
                  scanAssetId,
                  originAssetId
                  workspacePath
                  status,
                  scanAssetType,
                  parentScanAssetId,
                  attributionStatus,
                  matchType,
                  percentEmbedded,
                  averagePercentCopied,
                  matchCount,
                  matchScore,
                  otCount,
                  percentEmbedded,
                  isSupportedLicenseAnnotation,
                  policyViolationNotation {
                    policyId
                    policyName
                  },
                  assetRepositoryUrl {
                    data
                  },
                  scanId,
                  component {
                    componentId,
                    name,
                    group,
                    version,
                    isInternal,
                    description
                  },
                  embeddedAssets {
                        edges {
                          node {
                            name,
                            scanAssetId,
                            assetMatchId,
                            percentMatch,
                            matchScore,
                            latestReleaseVersion,
                            earliestReleaseVersion,
                            assetRepositoryUrl {
                              data
                            },
                            assetSize
                            matchLicenses {
                            category,
                            name,
                            licenseFamilyName,
                            needIncludeInCode,
                            attributionStatus,    
                            licenseId,
                            },
                            policyViolationNotation {
                              policyId
                              policyName
                            },
                            matchGroups {
                              edges {
                                node {
                                  name
                                  assetMatchId
                                  earliestReleaseVersion
                                  earliestReleaseDate
                                  latestReleaseDate
                                  latestReleaseVersion
                                  licensesByMatchGroup {
                                  category
                                  name
                                  }
                                }
                              }
                            }
                            matchRepository {
                              repositoryCode
                              repositoryOwner
                              repositoryName
                            }
                          },
                        }
                      }
                }
              }
            }
          }
        }
      `,
      fetchPolicy
    ); // , 'no-cache'
  }

  getScanAsset(scanId: string, scanAssetId: string) {
    return this.coreGraphQLService.coreGQLReq<ScanAssetQuery>(
      gql`
        query {
          scanAsset(scanId:"${scanId}" scanAssetId:"${scanAssetId}") {
            orgId,
            name,
            size,
            assetSize,
            scanAssetId,
            originAssetId
            workspacePath,
            status,
            percentEmbedded,
            averagePercentCopied,
            attributionStatus,
            matchScore,
            isSupportedLicenseAnnotation,
            assetRepositoryUrl {
              data
            },
            embeddedAssets {
              edges {
                node {
                  percentMatch,
                  percentCopied,
                  matchScore,
                  name,
                  assetSize,
                  assetMatchId,
                  originAssetId,
                  earliestReleaseDate,
                  earliestReleaseVersion,
                  latestReleaseDate,
                  latestReleaseVersion,
                  scanAssetId,
                  scanAssetMatchJiraTicket {
                    id, key, self
                  },
                  assetRepositoryUrl {
                    data
                  },
                  matchRepository{
                    repositoryOwner,
                    repositoryName,
                    repositoryId,
                    repositoryCode
                  },
                  matchGroups {
                    edges {
                        node {
                          name,
                          assetMatchId,
                          earliestReleaseDate,
                          earliestReleaseVersion,
                          latestReleaseDate,
                          latestReleaseVersion,
                          copyrights,
                          licensesByMatchGroup {
                            licenseId
                            name
                            spdxId
                            body
                            description
                            notes
                            category
                            style
                            publicationYear
                            type
                            compatible
                            incompatible
                            rejected
                            
                            isOrgLicense
                            
                            customerLicenseId
                            
                            attributes {
                                name
                                type
                                description
                            }
                          }
                        }
                    }
                  },
                  matchCopyrights {
                    copyrightText
                  },
                  matchLicenses {
                    licenseId,
                    licenseName,
                    licenseCategory,
                    earliestReleaseDate,
                    latestReleaseDate,
                    earliestReleaseVersion,
                    latestReleaseVersion,
                    needIncludeInCode,
                    attributionStatus
                  },
                  releases{
                    edges {
                      node {
                        releaseDate,
                        releaseName,
                        licenses {
                          edges {
                            node {
                              licenseId,
                              spdxId,
                              name,
                              shortName,
                              style,
                              category
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      'no-cache'
    );
  }

  getTestAssetContent() {
    return this.coreGraphQLService.coreGQLReq<any>(gql`
      query {
        getPatchedContent
      }
    `);
  }

  getAssetContent(url: string, registrationId: string) {
    return this.coreGraphQLService.coreGQLReq<GetAssetContentQuery>(gql`
            query {
                assetContent(url: "${url}", registrationId: "${registrationId}") { data }
            }
        `);
  }

  // send attribute asset graphql mutation
  attributeAsset(
    scanId: string,
    scanAssetId: string,
    licenses: any[],
    attributeComment: string
  ): any {
    const attributeAssetRequest: AttributeAssetRequestInput =
      new AttributeAssetRequestInput(
        scanId,
        scanAssetId,
        licenses,
        attributeComment
      );
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($attributeAssetRequest: AttributeAssetRequestInput) {
          attributeAsset(attributeAssetRequest: $attributeAssetRequest) {
            assetAttributionResults {
              assetId
              assetMatchesAttributions {
                assetId
                assetMatchId
                matchLicenseAttributions {
                  assetId
                  assetMatchId
                  attributionStatus
                  licenseId
                  scanId
                }
                scanId
              }
              attributionStatus
              scanId
            }
            assetsProcessedWithErrors
            attributionStatus
            message
            repoPullRequests
          }
        }
      `,
      { attributeAssetRequest }
    );
  }

  public attributeAssetsByLicense(
    scanId: string,
    licenseId: string,
    ignore: boolean
  ) {
    return this.coreGraphQLService.coreGQLReqForMutation<{
      attributeAssetsByLicenseAsyncSubmit: string;
    }>(
      gql`
        mutation ($scanId: String, $licenseId: String, $ignore: Boolean) {
          attributeAssetsByLicenseAsyncSubmit(
            scanId: $scanId
            licenseId: $licenseId
            ignore: $ignore
          )
        }
      `,
      { scanId, licenseId, ignore }
    );
  }

  public getAttributionAssetsNumberByLicense(
    licenseId: string,
    scanId: string
  ) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
          query {
            attributionAssetsCountByLicense(licenseId: "${licenseId}", scanId: "${scanId}")
          }
        `,
      'no-cache'
    );
  }

  public getProjectLatestScan(projectId: string) {
    return this.coreGraphQLService.coreGQLReq<ProjectQuery>(
      gql`
          query {
            project(projectId: "${projectId}") {
              latestScan {
                branch
                scanRepository {
                  repositoryId
                  repositoryEndpointType
                  repositoryName
                  repositoryOwner
                  repositoryType
                  registrationId
                }
              }
            }
          }
        `,
      'no-cache'
    );
  }

  public getProjectScans(projectId: string) {
    return this.coreGraphQLService.coreGQLReqWithQuery<ProjectQuery>(
      gql`
          query {
            project(projectId: "${projectId}") {
              scans {
                edges {
                  node {
                    branch
                    commit
                    componentCount
                    created
                    errorMsg
                    version
                    tag
                    status
                    scanId
                    versionHash
                    scanMetricsSummary {
                      vulnerabilityMetrics {
                        critical
                        high
                        info
                        low
                        medium
                        unassigned
                      }
                      licenseMetrics {
                        copyleft
                        copyleftLimited
                        copyleftPartial
                        copyleftStrong
                        copyleftWeak
                        custom
                        dual
                        permissive
                        proprietary
                        proprietaryFree
                        publicDomain
                      }
                      assetMetrics {
                        embedded
                        openSource
                        unique
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      'no-cache'
    );
  }

  public generateLicenseAttributionReport(projectId: string, scanId: string) {
    return this.coreGraphQLService.coreGQLReqWithQuery(
      gql`query {
  generateLicenseAttributionReport(projectId: "${projectId}", scanId: "${scanId}") {
    data
  }
    }`,
      'no-cache'
    );
  }

  public generateSupplyChainLicenseAttributionReport(
    entityId: string,
    projectIdToScanIdMap
  ) {
    return this.coreGraphQLService.coreGQLReqWithQuery(
      gql`
        query GenerateSupplyChainLicenseAttributionReport(
          $entityId: UUID
          $projectIdToScanIdMap: Map_UUID_UUIDScalar
        ) {
          generateSupplyChainLicenseAttributionReport(
            entityId: $entityId
            projectIdToScanIdMap: $projectIdToScanIdMap
          ) {
            data
          }
        }
      `,
      'no-cache',
      { entityId, projectIdToScanIdMap }
    );
  }

  public generateAiLicenseAttributionReport(
    entityId: string,
    projectIdToScanIdMap
  ) {
    return this.coreGraphQLService.coreGQLReqWithQuery(
      gql`
        query GenerateAiLicenseAttributionReport(
          $entityId: UUID
          $projectIdToScanIdMap: Map_UUID_UUIDScalar
        ) {
          generateAiLicenseAttributionReport(
            entityId: $entityId
            projectIdToScanIdMap: $projectIdToScanIdMap
          ) {
            data
          }
        }
      `,
      'no-cache',
      { entityId, projectIdToScanIdMap }
    );
  }

  public importProjectToScp(rootProjectId: string, scpProjectId: string): any {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation {
          importProjectToScp(
            rootProjectId: "${rootProjectId}"
            scpProjectId: "${scpProjectId}"
          ) {
            scpProject {
              scpProjectId
            }
          }
        }
      `
    );
  }
}
