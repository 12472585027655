import { Component, Input, OnInit } from '@angular/core';
import { ChartData } from '@app/models/charts';

@Component({
  selector: 'app-vulns-donut-chart',
  templateUrl: './vulns-donut-chart.component.html',
  styleUrls: ['./vulns-donut-chart.component.scss'],
})
export class VulnsDonutChartComponent implements OnInit {
  @Input() chartData: ChartData;

  @Input() chartClass: string;

  @Input() public showTitle: boolean = true;

  @Input() public color: string;

  private TITLE = 'Vulnerabilities';
  private COLORS = [
    '#e62222',
    '#ff5533',
    '#ffa033',
    '#e2e62e',
    '#70C7E2',
    '#b3b3b3'
  ];
  private chartLabels = [
    {
      label: 'Critical',
      class: 'critical',
    },
    {
      label: 'High',
      class: 'high',
    },
    {
      label: 'Medium',
      class: 'medium',
    },
    {
      label: 'Low',
      class: 'low',
    },
    {
      label: 'Info',
      class: 'info',
    },
    {
      label: 'Unassigned',
      class: 'unassigned',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.composeChartData();
  }

  // todo: ref: all new chart components have same method with same implementation, let's move it somewhere and get rid of code duplication.
  composeChartData() {
    this.chartData.title = this.showTitle ? this.TITLE : null;
    this.chartData.colors = this.COLORS;
    this.chartData.fill = { colors: this.COLORS };

    this.chartData.chartLabels = this.chartLabels;
    this.chartData.labels = this.chartLabels.map(({ label }) => label);
  }
}
