import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentNamePipe } from './component-name.pipe';
import { DiscoverPipe } from './discover.pipe';
import { SortPipe } from './sort.pipe';
import { ParseJsonPipe } from './json-parse.pipe';
import { PurlParserPipe } from './purl-parser.pipe';

@NgModule({
  declarations: [
    ComponentNamePipe,
    DiscoverPipe,
    SortPipe,
    ParseJsonPipe,
    PurlParserPipe,
  ],
  imports: [CommonModule],
  exports: [
    ComponentNamePipe,
    DiscoverPipe,
    SortPipe,
    ParseJsonPipe,
    PurlParserPipe,
  ],
})
export class PipesModule {}
