import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metricsTitleConverter',
})
export class MetricsTitleConverter implements PipeTransform {
  transform(titleAbbriviation: string, review?: boolean): string {
    switch (titleAbbriviation) {
      case 'I':
        return review ? 'Ignored' : 'Info';
      case 'C':
        return 'Critical';
      case 'M':
        return 'Medium';
      case 'L':
        return 'Low';
      case 'U':
        return 'Unassigned';
      case 'H':
        return 'High';
      case 'OS':
        return 'Open Source';
      case 'O':
        return 'Original';
      case 'E':
        return 'Embedded';
      case 'A':
        return 'Approved';
      case 'R':
        return 'Rejected';
      case 'P':
        return 'Pending';
      case 'A':
        return 'Approved';
      default:
        break;
    }
  }
}
