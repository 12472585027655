import {
  ScanAsset,
  ScanAssetsTreeConnection,
  ScanComponentConnection,
  ScanLicenseAssetConnection,
} from '@app/models/scan-asset';
import { License } from '@app/models/license';
import { Vulnerability } from './vulnerability';
import { ScanMetricsSummary } from './metrics';
import { Copyright, TxComponent } from '@app/models/component';
import { PageInfo } from '@app/models/paging';
import { Repository } from './repository';
import { GitlabProjects } from '@app/threat-center/dashboard/quickstart/quickstart.models';

export class ScanRequest {
  login: string;
  repositoryUrl?: string;
  repository: string;
  branch: string;
  entityId: string;
  repoType?: string;
  projectId: string;
  registrationId?: string;
  status?: string;
  url: string;
}

export class Task {
  taskToken?: string = '';
  status?: string = '';
  statusMessage?: string = '';
  pctComplete?: number;
  resourceId?: string = '';
  subtasks?: Task[] = [];
}

export interface ImageContainerScanRequest {
  scanRequest: {
    imageFullName: string;
    login: string;
    password: string;
    token: string;
  };
  entityId: string;
}

export class GitHubUser {
  id?: string;
  avatarUrl?: string;
  email?: string;
  name?: string;
  login?: string;
  token?: string;
  isUser?: boolean;
  loading: boolean;
  organizations: {
    edges: Organization[];
  };
  repositories: {
    edges: GithubRepository[];
  };
  // primaryLanguage: Language;
}

export class GithubRepository {
  node: {
    archived: boolean;
    createdAt: Date;
    defaultBranchRef: { name: string };
    description: string;
    fork: boolean;
    id: string;
    name: string;
    owner?: any;
    primaryLanguage?: string;
    private: boolean;
    refs: {
      edges: Array<{ node: { name: string } }>;
    };
    resourcePath: string;
    sshUrl: string;
    url: string;
  };
}

/** Model for GitLab user */
export class GitLabUser {
  id?: string;
  avatarUrl?: string;
  email?: string;
  name?: string;
  username?: string;
  token?: string;
  gitLabProjects: GitlabProjects[];
  repositories?: GitlabProjects[];
}

// Model for bitbucket user
export class BitbucketUser {
  id?: string = '';
  avatarUrl?: string = '';
  email?: string = '';
  name?: string = '';
  login?: string;
  slug?: string;
  username?: string = '';
  token?: string = '';
  workspaces?: any[];
  bitbucketProjects?: BitbucketRepository[];
  loading?: boolean;
  uuid?: string;
  repositories?: BitbucketRepository[];
  links?: {
    avatar: { href: string };
    html: { href: string };
    projects: { href: string };
    repositories: { href: string };
  };
}
export interface BitbucketRepository {
  name: string;
  fullName: string;
  url: string;
  sshUrl?: string;
  owner?: string;
  createdOn?: Date;
  description?: string;
  language?: string;
  mainBranch?: string;
  private?: boolean;
  branches?: Array<string>;
  scanBranch?: string;
}

export class Organization {
  node: {
    avatarUrl?: string;
    name?: string;
    repositories: {
      edges: Repository[];
    };
  };
}

export class Language {
  name?: string = '';
  color?: string = '';
}

export class UserSelection {
  repository: string;
  branch: string;
}

// todo: ref: duplicate class declaration (keep this one, remove another)
export class Scan {
  orgId: string;
  scanId: string;
  projectId: string;
  entityId: string;
  scanRepoId: string;
  repositoryId: string;

  branch: string;
  tag: string;
  commit: string;

  vulnerabilities: Vulnerability[];
  components: TxComponent[];
  scanRepository: Repository;
  scanMetrics: any;
  scanAssetsTree: ScanAssetsTreeConnection;
  scanAssetsTreeByParent?: Array<ScanAsset>;
  licenses: ScanLicense[];
  scanOpenSourceProject: ScanOpenSourceProject;
  created: Date;
  scanMetricsSummary: ScanMetricsSummary;

  componentCount: number;
  licenseCount: number;
  vulnerabilityCount: number;

  status: string;
  versionHash: string;
  errorMsg: string;
  log: string;

  auditScanAssetsCount: number;
  // tslint:disable-next-line:variable-name
  components_audit_count: number;
  isScmActionsAvailableForScan?: boolean;
  otMetaData?: any;
  supplyChainRiskScore?: SupplyChainRiskScore; //string
  typeOfScan?: TypeOfScan;
  otAssetsCount?: number;
}

export enum TypeOfScan {
  AGENT_CONTAINER = 'AGENT_CONTAINER',
  AGENT_LOCAL_FOLDER = 'AGENT_LOCAL_FOLDER',
  AZURE_DEVOPS_REPO = 'AZURE_DEVOPS_REPO',
  BITBUCKET_REPO = 'BITBUCKET_REPO',
  CODE_COMMIT_REPO = 'CODE_COMMIT_REPO',
  GIT_HUB_REPO = 'GIT_HUB_REPO',
  GIT_LAB_REPO = 'GIT_LAB_REPO',
  UNKNOWN = 'UNKNOWN',
  UPLOADED_ARCHIVE = 'UPLOADED_ARCHIVE',
  UPLOADED_BOM_FILE = 'UPLOADED_BOM_FILE',
}

export interface SupplyChainRiskScore {
  supplyChainRiskScore: {
    name: string;
    label: string;
    value: number;
  };
  scores: Array<{
    name: string;
    label: string;
    value: number;
  }>;
}

export class ScanOpenSourceProject {
  owner: string;
  name: string;
  repoWebsite: string;
}

export class LicenseAssetAttribution {
  attributionStatus: string;
  message: string;
}

export class ScanConnection {
  edges: ScanEdge[];
  pageInfo: PageInfo;
  totalCount: number;
}

export class ScanEdge {
  node: Scan;
  cursor: string;
}

// duplicate of License
export class ScanLicense {
  orgId: string;
  scanId: string;
  licenseId: string;
  licenseDiscovery: string;
  spdxId: string;
  name: string;
  style: string;
  type: string;
  publicationYear: number;
  isOsiApproved: boolean;
  isFsfLibre: boolean;
  category: string;
  licenseOrigin: string;
  license: License;
  assetCount: number;
  scanComponents: ScanComponentConnection;
  scanAssetsTree: ScanAssetsTreeConnection;
  scanLicenseAssets: ScanLicenseAssetConnection;
  scanOpenSourceProject: ScanOpenSourceProject;
  requireAttribution?: boolean;
  attributionStatus: string;
  isOrgLicense: boolean;
}

export class JiraTicket {
  id: string;
  key: string;
  self: string;
}

export class DeploymentSettings {
  orgId: string;
  deploymentMode: string;
  cloudServerUrl = 'https://app.threatrix.io';
  cloudThreatrixSchemeIp = '10.128.0.2';
  cloudApiKey: string;
}

export class SnippetMatchResult {
  matchTime: number;
  scanTime: number;
  snippetSize: number;
  matchSize: number;
  snippetMatches: SnippetMatch[];
  otcount: number;
}

export class SnippetMatch {
  matchAssetId: string;
  repositoryName: string;
  repositoryOwner: string;
  assetName: string;
  matchPercent: number;
  matchScore: number;
  earliestRelease: AssetRelease;
  latestRelease: AssetRelease;
  earliestReleaseLicenses: RepositoryReleaseLicense[];
  latestReleaseLicenses: RepositoryReleaseLicense[];
  repositoryLicenses: RepositoryLicense[];
  assetLicenses: AssetLicense[];
  duplicates: number;
  copyrights: Copyright[];
}

export class AssetRelease {
  releaseDate: Date;
  releaseName: string;
}

export class AssetLicense {
  licenseId: string;
  licenseName: string;
  licenseContext: string;
  name: string;
}
export class RepositoryLicense {
  licenseId: string;
  licenseName: string;
}
export class RepositoryReleaseLicense {
  licenseId: string;
  licenseName: string;
}

// GRAPHQL QUERIES
export type TaskQuery = {
  task_submitScanRequest: Task;
  fetchRepo: Task;
  task_update: Task;
};

export type GitHubUserQuery = {
  gitHubUser: GitHubUser;
};

export type GitHubUserAndOrgsQuery = {
  githubUserAndOrgs: GitHubUser;
};

// GitLabUserQuery
export type GitLabUserQuery = {
  gitLabUser: GitLabUser[];
};

// BitbucketUserQuery
export type BitbucketUserQuery = {
  bitbucketUser: BitbucketUser;
};

export type ScanQuery = {
  scan: Scan;
};

export type SnippetQuery = {
  snippetMatchResult: SnippetMatchResult;
};

// check if already scanned
export type CheckAlreadyScannedQuery = {
  checkAlreadyScannedProject: Date;
};

export type CheckAlreadyScannedScpModuleQuery = {
  checkAlreadyScannedScpModule: Array<AlreadyScannedScpModule>;
};

export interface AlreadyScannedScpModule {
  scpProjectTags: string;
  scpProjectBranches: string;
  orgId: string;
  entityId: string;
  scpProjectType: string; // 'CODECERTIFY' | 'AICERTIFY
  scpProjectName: string;
  scpProjectId: string;
}

export type ScanLicenseQuery = {
  scanLicense: ScanLicense;
};

export type JiraTicketQuery = {
  licenseJiraTicket: JiraTicket;
};

export interface DeploymentDataQuery {
  deploymentSettings: DeploymentSettings;
}

export interface ScanWorkflowRequest {
  entityId: string;
  triggerEvent: Array<string>;
  repoType: string;
  appName: string;
  branch: string;
  repoOwner: string;
  repository: string;
  repoUrl?: string;
  trxUrl?: string;
}
