import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { catchError, map, mergeMap, pluck } from 'rxjs/operators';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';

import { ApolloQueryResult } from 'apollo-client';
import { NextConfig } from '@app/app-config';
import {
  AlreadyScannedScpModule,
  CheckAlreadyScannedScpModuleQuery,
  Task,
  TaskQuery,
} from '@app/models';
import { MESSAGES } from '@app/messages/messages';

import { AlertService } from '@app/services/core/alert.service';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import { TaskService } from '@app/services/task.service';

import { PreScanLoadingDialogComponent } from '@app/threat-center/dashboard/pre-scan-dialog/pre-scan-dialog.component';
import { UserPreferenceService } from './core/user-preference.service';
import { NavigationService } from './navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { AlreadyAttachedModalComponent } from '@app/shared/components/already-attached-modal/already-attached-modal.component';

@Injectable()
export class ScanHelperService {
  private isHighlightNewScan = new BehaviorSubject(false);
  isHighlightNewScanObservable$ = this.isHighlightNewScan.asObservable();

  private isEnabaleNewScan = new BehaviorSubject(false);
  isEnabaleNewScanObservable$ = this.isEnabaleNewScan.asObservable();
  // private isRefreshObjectPage = new BehaviorSubject(false);
  isRefreshObjectPage = new BehaviorSubject(false);
  isRefreshObjectPageObservable$ = this.isRefreshObjectPage.asObservable();

  sub: Subscription;

  projectScanResults: Array<any> = [];
  recentlyScanCompleted: Array<any> = [];
  errorScanProject: Array<any> = [];
  public isLoggedOut: boolean = false;
  public reloadNecessary: Subject<boolean> = new Subject();
  public closeModal: Subject<boolean> = new Subject();
  public pageType?: 'AI_CERTIFY' | 'SUPPLY_CHAIN';

  constructor(
    private taskService: TaskService,
    private router: Router,
    private modalService: NgbModal,
    private alertService: AlertService,
    private coreGraphQLService: CoreGraphQLService,
    private userPreferenceService: UserPreferenceService,
    private navigationService: NavigationService,
    public dialog: MatDialog
  ) {}

  //Scan submit and get task token to do further for scan.
  public submitingScanForProject(preScanProjectData) {
    const object = {
      projectId: '',
      uniqId: preScanProjectData['uniqId'],
      projectName: preScanProjectData['projectName'],
      entityId: preScanProjectData['entityId'],
      scanStatus: '',
      taskToken: '',
      pageType: this.pageType,
    };

    this.projectScanResults.push(object);
    this.taskService
      .submitScanRequest()
      .pipe(map((task) => task.data.fetchRepo))
      .subscribe((task) => {
        this.projectScanResults.forEach((pro) => {
          if (pro.uniqId == preScanProjectData['uniqId']) {
            pro.taskToken = task.taskToken;
          }
        });
        this.getTaskUpdate(task);
        this.userPreferenceService.settingUserPreference(
          'Project',
          null,
          null,
          null,
          null,
          null,
          null,
          ''
        );
      });
  }

  public submitingUploadProject(preScanProjectData) {
    const object = {
      projectId: '',
      uniqId: preScanProjectData.uniqId,
      projectName: preScanProjectData.projectName,
      entityId: preScanProjectData.entityId,
      scanStatus: '',
      taskToken: preScanProjectData.taskToken,
      pageType: this.pageType,
    };
    this.projectScanResults.push(object);
  }

  /**
   * Scan progress and get stats from server and updating needed thing status wise.
   *
   * @param task scan task
   */
  getTaskUpdate(task: Task) {
    if (this.isLoggedOut) {
      return;
    }
    this.taskService
      .getTaskUpdate(task.taskToken)
      .pipe(
        catchError((_, caught$) =>
          caught$.pipe(
            mergeMap((query) => {
              const errorTask = query.data.task_update;

              this.scanErrorHandler(errorTask);

              return EMPTY;
            })
          )
        ),
        pluck<ApolloQueryResult<TaskQuery>, Task>('data', 'task_update')
      )
      .subscribe({
        next: (tUpdate: Task) => {
          this.updateProjectArray(tUpdate);
          if (
            tUpdate.status === 'COMPLETE' ||
            tUpdate.status === 'COMPLETE_WITH_ERRORS'
          ) {
            this.reloadNecessary.next(true);
            //show toaster and pop one from main list and add into recent scan..
            const obj = this.projectScanResults.find(
              (pro) => pro.taskToken === tUpdate.taskToken
            );
            if (!!obj) {
              obj['CompletedTime'] = new Date();
              this.recentlyScanCompleted.push(obj);
            }
            this.projectScanResults = this.projectScanResults.filter(
              (pro) => pro.taskToken !== tUpdate.taskToken
            );
            if (tUpdate.status === 'COMPLETE_WITH_ERRORS') {
              const message: string = !!tUpdate.statusMessage
                ? tUpdate.statusMessage
                : 'Scan is completed with errors';
              this.alertService
                .alertBox(message, 'Warning', 'warning')
                .then(() => {
                  this.highlightNewScanIfInSamePage(tUpdate);
                  this.refreshObjectPageIfFirstScan();
                });
            } else {
              this.highlightNewScanIfInSamePage(tUpdate);
              this.refreshObjectPageIfFirstScan();
            }
            //remove scan from storage
            this.updateStorage(null);

            //Update Execute Scan Button
            this.updateEnabaleNewScan(false);
          } else if (tUpdate.status === 'ERROR') {
            this.reloadNecessary.next(true);

            let statusMessage = tUpdate.statusMessage;
            if (statusMessage === null || statusMessage === 'null') {
              statusMessage = 'Unexpected scanning error.';
            }

            console.error('Task Error: ', statusMessage);

            const obj = this.projectScanResults.find(
              (pro) => pro.taskToken === tUpdate.taskToken
            );
            if (!!obj) {
              obj['CompletedTime'] = new Date();
              this.recentlyScanCompleted.push(obj);
            }
            this.projectScanResults = this.projectScanResults.filter(
              (pro) => pro.taskToken !== tUpdate.taskToken
            );
            this.alertService.alertBox(
              tUpdate.statusMessage,
              MESSAGES.ERROR_TITLE,
              'error'
            );

            //remove scan from storage
            this.updateStorage(null);
            //Update Execute Scan Button
            this.updateEnabaleNewScan(false);
          } else {
            setTimeout(() => {
              this.getTaskUpdate(task);
            }, NextConfig.config.delaySeconds);
            this.updateStorage(task.taskToken);
            //Update Execute Scan Button
            this.updateEnabaleNewScan(true);
          }
        },
        error: () => {
          this.scanErrorHandler(task);
        },
        complete: () => {
          this.scanErrorHandler(task);
        },
      });
  }

  //Highlite new scan on project page or not
  public updateIsHighlightNewScan(val) {
    this.isHighlightNewScan.next(val);
  }

  //Helper functions
  public updateProjectArray(taskUpdate) {
    this.projectScanResults.forEach((project) => {
      if (taskUpdate.taskToken === project.taskToken) {
        project['projectId'] = taskUpdate.resourceId;
        project['scanStatus'] = taskUpdate.status;
      }
    });
  }

  /**
   * Scan error handler
   *
   * @param task scan task
   */
  public scanErrorHandler(task: Task) {
    const projectScanResult = this.projectScanResults.find(
      (project) => project.taskToken === task.taskToken
    );

    if (projectScanResult) {
      projectScanResult.CompletedTime = new Date();

      this.errorScanProject.push(projectScanResult);
    }

    this.projectScanResults = this.projectScanResults.filter(
      (project) => project.taskToken !== task.taskToken
    );

    this.alertService.alertBox(
      task.statusMessage || MESSAGES.ERROR_MESSAGE,
      MESSAGES.ERROR_TITLE,
      'error'
    );

    this.updateStorage(null);
    this.updateEnabaleNewScan(false);
  }

  //Navigate to project page when click on prject from floating model
  public gotoProjectAndUpdateRecentScan(project) {
    let pageUrl: string;
    if (this.pageType === 'AI_CERTIFY' || project.pageType === 'AI_CERTIFY') {
      pageUrl = 'aicertify/entity/' + project.entityId;
    } else if (
      this.pageType === 'SUPPLY_CHAIN' ||
      project.pageType === 'SUPPLY_CHAIN'
    ) {
      pageUrl = 'codecertify/entity/' + project.entityId;
    } else {
      pageUrl =
        'security/entity/' + project.entityId + '/project/' + project.projectId;
    }

    this.recentlyScanCompleted = this.recentlyScanCompleted.filter(
      (pro) => pro.projectId !== project.projectId
    );
    this.router.navigate([pageUrl], { state: { from: 'DIALOG' } });
  }

  public openScanModel(preScanProjectData): NgbModalRef {
    const modalRef: NgbModalRef = this.modalService.open(
      PreScanLoadingDialogComponent,
      {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'pre-scan-loading-dialog',
        backdropClass: 'pre-scan-loading-dialog-backdrop',
      }
    );
    modalRef.componentInstance.preScanProjectData = preScanProjectData;

    modalRef.result.then(() => {
      this.closeModal.next(true);
    });

    return modalRef;
  }

  public checkIfAlreadyInOtherSCPModule(
    preScanProjectData: any,
    LoadingDialogComponent: any
  ) {
    this.taskService
      .checkAlreadyScannedScpModule(
        this.checkScannedErrorHandler.bind(
          this,
          'ScanHelperService#submitingCheckAlreadyScanned'
        )
      )
      .subscribe(
        (check: ApolloQueryResult<CheckAlreadyScannedScpModuleQuery>) => {
          const scpModuleData = check.data
            ? check.data.checkAlreadyScannedScpModule
            : null;

          const alreadyScannedInThisProject = scpModuleData.find(
            (scp: AlreadyScannedScpModule) =>
              scp.scpProjectId === preScanProjectData.entityId
          );

          if (alreadyScannedInThisProject) {
            return this.runScan(preScanProjectData, LoadingDialogComponent);
          }

          if (scpModuleData && scpModuleData.length > 0) {
            const dialogRef = this.dialog.open(AlreadyAttachedModalComponent, {
              width: '500px',
              data: {
                scpModuleData,
                currentScpName: preScanProjectData.projectName,
                currentProjectType: this.pageType,
              },
              panelClass: 'already-scanned-modal',
            });

            dialogRef.afterClosed().subscribe((result) => {
              this.updateStorage(null);
              this.updateEnabaleNewScan(false);
              if (result === 'scan') {
                this.runScan(preScanProjectData, LoadingDialogComponent);
                // this.navigationService.navigateToProject(
                //   scpModuleData.scpProject.scpProjectType as any,
                //   scpModuleData.scpProject.scpProjectId,
                //   null
                // );
              } else if (result.action === 'navigate') {
                this.navigationService.navigateToProject(
                  scpModuleData[result.index].scpProjectType as any,
                  scpModuleData[result.index].scpProjectId,
                  null
                );
              }
            });

            // Swal.close();

            // const alertTitle: string = `Project was already scanned`;
            // const alertText = `Module cannot be added because it's already attached to
            // ${scpModuleData.scpProject.scpProjectName} at ${lastCheckDate}.
            // Do you want to navigate to ${scpModuleData.scpProject.scpProjectName}
            // project or do you want to scan module again there?`;

            // this.alertService
            //   .alertBoxNavigate(alertTitle, alertText)
            //   .then(({ value }) => {
            //     console.log(value);
            //     this.updateStorage(null);
            //     this.updateEnabaleNewScan(false);
            //     // if (value) {
            //     //   this.navigationService.navigateToProject(
            //     //     scpModuleData.scpProject.scpProjectType as any,
            //     //     scpModuleData.scpProject.scpProjectId,
            //     //     null
            //     //   );
            //     // }
            //   });
          } else {
            // run scan
            return this.runScan(preScanProjectData, LoadingDialogComponent);
          }
        }
      );
  }

  /** Check if latest commit for a branch was scanned already and run scan. */
  public submitingCheckAlreadyScanned(
    preScanProjectData: any,
    LoadingDialogComponent: any,
    pageType?: 'AI_CERTIFY' | 'SUPPLY_CHAIN'
  ) {
    // todo
    if (pageType) {
      return this.checkIfAlreadyInOtherSCPModule(
        preScanProjectData,
        LoadingDialogComponent
      );
    }
    this.taskService
      .checkAlreadyScannedProject(
        this.checkScannedErrorHandler.bind(
          this,
          'ScanHelperService#submitingCheckAlreadyScanned'
        )
      )
      .pipe(
        pluck('data', 'checkAlreadyScannedProject'),
        catchError(
          this.checkScannedErrorHandler.bind(
            this,
            'ScanHelperService#submitingCheckAlreadyScanned'
          )
        )
      )
      .subscribe((check) => {
        if (check) {
          Swal.close();
          const lastCheckDate = moment(check).format('MM/DD/YYYY h:mm a');

          const alertTitle = `Project was scanned already at ${lastCheckDate}`;
          const alertText = 'Do you want to scan anyway?';

          this.alertService
            .alertConfirm(
              alertTitle,
              alertText,
              'question',
              true,
              true,
              '#4680ff',
              '#6c757d',
              'Yes',
              'No'
            )
            .then(({ value }) => {
              if (value) {
                // run scan
                this.runScan(preScanProjectData, LoadingDialogComponent);
              } else {
                // cancel scan
                this.updateStorage(null);
                this.updateEnabaleNewScan(false);
              }
            });
        } else {
          // run scan
          return this.runScan(preScanProjectData, LoadingDialogComponent);
        }
      });
  }

  private checkScannedErrorHandler(
    errorSource: string,
    error: HttpErrorResponse | any,
    source: Observable<any>
  ) {
    // cancel scan
    this.updateStorage(null);
    this.updateEnabaleNewScan(false);

    // default error handler
    return this.coreGraphQLService.errorHandler(errorSource, error, source);
  }

  // run scan
  private runScan(preScanProjectData, LoadingDialogComponent): Promise<any> {
    return this.openScanModel(preScanProjectData)
      .result.then(
        (result) => {
          this.openFloatingModal(LoadingDialogComponent);
        },
        (reason) => {}
      )
      .then(() => {
        this.submitingScanForProject(preScanProjectData);
      });
  }

  //floating model for scan...
  public openFloatingModal(loadingDialogComponent) {
    const modalRef = this.modalService.open(loadingDialogComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'loading-dialog',
      backdropClass: 'loading-dialog-backdrop',
    });
  }

  //helper function.
  private highlightNewScanIfInSamePage = (tUpdate) => {
    const projectId = tUpdate.resourceId;
    const lastSegOfUrl = this.router.url.substring(
      this.router.url.lastIndexOf('/') + 1
    );
    if (!!projectId && !!lastSegOfUrl && projectId === lastSegOfUrl) {
      this.updateIsHighlightNewScan(true);
    } else {
      this.updateIsHighlightNewScan(false);
    }
  };

  //execute scan button diable and enebale
  private updateEnabaleNewScan(val: boolean) {
    this.isEnabaleNewScan.next(val);
  }

  //refresh entity page if fisrt scan.
  private refreshObjectPageIfFirstScan() {
    this.isRefreshObjectPage.next(true);
  }

  private updateStorage(taskToken: string | null) {
    if (taskToken === null) {
      localStorage.removeItem('SCANNING_PROJECTS');
    } else {
      localStorage.setItem(
        'SCANNING_PROJECTS',
        JSON.stringify(this.projectScanResults)
      );
    }
  }
}
