<div>
  <b>Component Releases</b>

  <div class="line header">
    <div style="width: 20%">Version</div>
    <div style="width: 32%">Release Date</div>
    <div style="width: 48%">Vulnerabilities</div>
  </div>

  <div
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollUpDistance]="1.5"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScrollDown()"
    [style.max-height]="'125px'"
    style="padding-right: 10px; overflow-y: auto"
  >
    <div
      class="line versions"
      [class]="'versions-' + componentVersion?.vulnerabilities?.maxSeverity"
      *ngFor="
        let componentVersion of component?.component?.componentVersions
          ?.resultList;
        let index = index
      "
    >
      <div
        class="version"
        [ngbTooltip]="componentVersion.version"
        style="width: 20%"
        [ngStyle]="{
          'padding-left': componentVersion.vulnerabilities?.maxSeverity
            ? '0px'
            : '8px'
        }"
      >
        {{ componentVersion.version }}
      </div>

      <div style="width: 32%">
        {{
          componentVersion.releaseDate
            ? (componentVersion.releaseDate | date : 'MMM dd, yyyy')
            : '-'
        }}
      </div>

      <div class="vuln-column" style="width: 48%">
        <ng-container
          *ngFor="
            let vuln of componentVersion.vulnerabilitiesSeveretyTotal | keyvalue
          "
        >
          <div class="mini-bubble" [class]="vuln.key" *ngIf="vuln.value">
            <span class="number">{{ vuln.value }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      !componentVersionsLoading &&
      component?.component?.componentVersions?.resultList?.length === 0
    "
    role="alert"
    class="alert alert-primary"
  >
    There are no detected versions
  </div>
</div>
