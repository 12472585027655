<app-ui-modal #licenseAddDialog>
    <!-- HEADER ================================================================================================= -->
    <div class="app-modal-header">
        <h5 class="modal-title">{{ isLicenseEdit ? 'License Editing Dialog' : 'License Adding Dialog' }}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="licenseAddDialog.hide()"><span aria-hidden="true">&times;</span></button>
    </div>

    <!-- BODY ================================================================================================= -->
    <div class="app-modal-body">
        <!-- SEARCH -->
        <div class="mb-4" *ngIf="!isLicenseEdit">
            <div class="info-cols"><small>Search license to use it as a base for custom license</small></div>
            <input id="typeahead-format" type="text" class="form-control" placeholder="Type name of existing license"
                [(ngModel)]="typeaheadModel" [ngbTypeahead]="search" [inputFormatter]="formatter"
                [resultFormatter]="formatter" [editable]='false' (selectItem)="setLicenseProperties($event)" />
        </div>
        <!-- LICENSE ID -->
        <div class="mb-4" *ngIf="isLicenseEdit">
            <div class="info-cols"><small>License ID</small></div>
            <input id="typeahead-format" type="text" class="form-control" placeholder="License ID"
                [(ngModel)]="licenseId" #licenseIdModel="ngModel" required/>
            <div class="invalid-form"
                    *ngIf="(licenseIdModel.errors && (licenseIdModel.dirty || licenseIdModel.touched))">
                    <small *ngIf="licenseIdModel.errors && licenseIdModel.errors?.required">License
                        Id
                        is
                        required.</small>
                </div>
        </div>


        <div class="row mb-2">
            <!-- NAME -->
            <div class="col-sm-6">
                <div class="info-cols"><small>License name</small></div>
                <input [(ngModel)]="licenseName" type="text"
                    class="form-control compliance-dashboard-add-license-dialog-input">
            </div>

            <!-- CATEGORY -->
            <div class="col-sm-6">
                <div class="info-cols"><small>Category</small></div>
                <div>
                    <select name="typeFilter" class="form-control custom-select" [(ngModel)]="licenseCategory"
                        #licenseCategoryModel="ngModel" required>
                        <option value=""></option>
                        <option value="{{category.id}}" *ngFor="let category of categories">{{category.name}}</option>
                    </select>
                    <div class="invalid-form"
                        *ngIf="(licenseCategoryModel.errors && (licenseCategoryModel.dirty || licenseCategoryModel.touched))">
                        <small *ngIf="licenseCategoryModel.errors && licenseCategoryModel.errors?.required">License
                            category name
                            is
                            required.</small>
                    </div>
                </div>
            </div>
        </div>


        <!-- TEXT -->
        <div class="info-cols"><small>License text</small></div>
        <textarea [(ngModel)]="licenseText" type="text" #licenseTextModel="ngModel"
            class="form-control compliance-dashboard-add-license-dialog-input license-text" required></textarea><!-- todo: ref: move style into scss -->
        <div class="invalid-form"
            *ngIf="(licenseTextModel.errors && (licenseTextModel.dirty || licenseTextModel.touched))">
            <small *ngIf="licenseTextModel.errors && licenseTextModel.errors?.required">License text
                is
                required.</small>
        </div>

    </div>


    <!-- FOOTER ================================================================================================= -->
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="licenseAddDialog.hide()">Close</button>
        <button type="button" class="btn btn-primary ml-2" (click)="saveLicense(licenseTextModel, licenseCategoryModel)">{{ isLicenseEdit ? 'Save license' : 'Add License' }}</button>
    </div>
</app-ui-modal>
