import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import gql from 'graphql-tag';
import { License, ScanLicense } from '@app/models';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { FetchResult } from 'apollo-link';

@Injectable()
export class LicenseService {
  constructor(private coreGraphQLService: CoreGraphQLService) { }

  public getLicenseByName(name: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`query {
      licensesByNameFilter(filter:"${name}") {
        licenseId,
        name,
        category,
        body
      }
    }`,
      'no-cache'
    );
  }

  public getLicense(scanLicense: ScanLicense) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            query ($isOrgLicense: Boolean) {
                getLicenseForOrgAndLicenseId(
                    orgId: "${scanLicense.orgId}",
                    licenseId: "${scanLicense.licenseId}",
                    isOrgLicense: $isOrgLicense
                ) {
                    licenseId,
                    name,
                    category,
                    body
                }
            }`,
      'no-cache',
      { isOrgLicense: scanLicense.isOrgLicense }
    );
  }

  public getLicenseURI(scanComponent, licenseId) {
    const scanComponentInput = {
      purl: scanComponent.purl,
    };
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query ($scanComponentInput: ScanComponentInput, $licenseId: String) {
          licenseURI(scanComponent: $scanComponentInput, licenseId: $licenseId)
        }
      `,
      'no-cache',
      { scanComponentInput, licenseId }
    );
  }

  public licenseListForPath(
    entityId: string,
    scanId: string,
    assetPath: string,
    assetsLicenseOnly: boolean = false,
    showCardLicenses: boolean = false,
    filters?: string
  ) {
    let params: string = entityId ? `, entityId: "${entityId}"` : '';
    params += scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    params += filters
      ? `, filter: "${filters}"`
      : ', filter: "showReviewed:true"';

    return this.coreGraphQLService.coreGQLReq<any>(
      gql`query {
        licenseListForPath(assetsLicenseOnly: ${assetsLicenseOnly}, showCardLicenses: ${showCardLicenses}, ${params}) {
          licenseList {
            licenseId
            name
            category
            licenseDiscovery
            licenseOrigin
          }
          licenseMetrics {
            copyleft
            copyleftLimited
            copyleftPartial
            copyleftStrong
            copyleftWeak
            custom
            dual
            permissive
            proprietary
            proprietaryFree
            publicDomain
          }
        }
      }`,
      'no-cache'
    );
  }

  public componentLicensesForPath(
    entityId: string,
    scanId: string,
    assetPath: string,
    filters?: string
  ) {
    let params: string = entityId ? `entityId: "${entityId}"` : '';
    params += scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    params += filters ? `, filters: "${filters}"` : '';

    return this.coreGraphQLService.coreGQLReq<any>(
      gql`query {
        componentLicensesForPath(${params}) {
          licenseList {
            category
              license {
                category
                cleanName
                compatible
                name
                version
                type
              }
          licenseId
            name
        type
        licenseDiscovery
        licenseOrigin
       }
       licenseMetrics {
        copyleft
        copyleftLimited
        copyleftPartial
        copyleftStrong
        copyleftWeak
        custom
        dual
        permissive
        proprietary
        proprietaryFree
        publicDomain
      }
      }
    }`,
      'no-cache'
    );
  }

  public getLicenseList(name?: string, category?: string, licenseId?: string): Observable<ApolloQueryResult<{
    licenseList: Array<License>;
  }>> {
    return this.coreGraphQLService.coreGQLReq<{ licenseList: Array<License> }>(
      gql`
        query {
            licenseList(filter: { name: ${name ?? 'null'}, category: ${category ?? 'null'}, licenseId: ${licenseId ?? 'null'} }) {
              licenseId
              name
              category
              isOsiApproved
              isFsfLibre
              publicationYear
              style
              isOrgLicense
              type
              compatible
              incompatible
              notes
              description
              body
              spdxId
              attributes {
                  description
                  name
                  type
              }
          }
        }`,
      'no-cache',
    );
  }

  public getOrgLicenseList(name?: string, category?: string, licenseId?: string): Observable<ApolloQueryResult<{
    orgLicenseList: Array<License>;
  }>> {
    return this.coreGraphQLService.coreGQLReq<{ orgLicenseList: Array<License> }>(
      gql`
        query  {
            orgLicenseList (filter:  { name: ${name ?? 'null'}, category: ${category ?? 'null'}, licenseId: ${licenseId ?? 'null'} }) {
              licenseId
              name
              category
              body
              customerLicenseId
              isOrgLicense
          }
        }`,
      'no-cache',
    );
  }

  public updateOrgLicense(orgLicense: License): Observable<FetchResult<{
    updateOrgLicense: License;
  }>> {
    return this.coreGraphQLService.coreGQLReqForMutation<{ updateOrgLicense: License }>(
      gql`
          mutation UpdateOrgLicense {
              updateOrgLicense(
                  licenseData: {
                      customerLicenseId: ${JSON.stringify(orgLicense.customerLicenseId)}
                      licenseCategory: ${orgLicense.category}
                      name: ${JSON.stringify(orgLicense.name)}
                      body: ${JSON.stringify(orgLicense.body)}
                      licenseId: "${orgLicense.licenseId}"
                  }
              ) {
                  customerLicenseId
                  category
                  body
                  name
                  licenseId
              }
          }
        `,
    );
  }
}
