import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Scan,
  ScanComponent,
  ScanComponentEdge,
  ScanQuery,
  ScanVulnerabilityEdge,
  VulnerabilityEdge,
} from '@app/models';
import { UserPreferenceService } from '@app/services/core/user-preference.service';
import { ProjectService } from '@app/services/project.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { NextConfig } from '@app/app-config';
import { FilterStateService } from '@app/services/core/filter-state.service';
import { ApolloQueryResult } from 'apollo-client';
import { AuthorizationService } from '@app/security/services';
import { ComponentVulnerabilitiesInfoNewDialogComponent } from '@app/shared/components/component-vulnerabilities-info-new-dialog/component-vulnerabilities-info-new-dialog.component'; // eslint-disable-line
import { ScanComponentService } from '@app/services/scan-component.service';
import { FixComponentDialogComponent } from '@app/compliance-dashboard-new/common/fix-component-dialog/fix-component-dialog.component';
import { LicenseDialogComponent } from '@app/compliance-dashboard-new/common/licenses-common-dialog/license-dialog.component';
import { vulnerabilitiesSortOrder } from '@app/threat-center/dashboard/project/component/component-detail-new/store/component-detail.store';

@Component({
  selector: 'app-component-new-card-new',
  templateUrl: './new-component-card.component.html',
  styleUrls: ['./new-component-card.component.scss'],
})
export class NewComponentCardComponent implements OnInit, OnDestroy {
  @Input() public isShowCompositeData: boolean;
  @Input() public scanId: string;
  @Input() public licenseId: string;
  @Input() public licenseDiscovery: string;
  @Input() public licenseOrigin: string;
  @Input() public isLicensePage: boolean = false;

  public newVersion: string;
  public showAlert: boolean;

  public defaultPageSize: number = NextConfig.config.defaultPageSize;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public componentDetails: {
    components: {
      edges: Array<ScanComponentEdge>;
      pageInfo: any;
      totalCount: number;
    };
    isScmActionsAvailableForScan: boolean;
    scanId: string;
  };

  public columnsFilter = new Map();
  public timeOut;
  public timeOutDuration = 1000;
  public isDisablePaggination: boolean = false;

  // filters values
  public isInternal: boolean = false;
  public isVulnerabilities: boolean = false;
  public isHasFix: boolean = false;

  public isFix: boolean = false;
  public isScmActionsAvailableForScan: boolean;
  public filtered: boolean = false;
  public loading: boolean = false;

  public DefaultVulnerabilitiesSeveretyTotal = {
    CRITICAL: 0,
    HIGH: 0,
    MEDIUM: 0,
    LOW: 0,
    INFO: 0,
    UNASSIGNED: 0,
  };

  public constructor(
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private userPreferenceService: UserPreferenceService,
    private filterStateService: FilterStateService,
    public authorizationService: AuthorizationService,
    private scanComponentService: ScanComponentService
  ) {}

  public ngOnInit(): void {
    this.initComponentData();
  }

  public initComponentData(): void {
    this.columnsFilter = this.filterStateService.getFilter(
      this.isLicensePage ? 'licenseComponents' : 'components'
    );
    this.filtered = this.hasAnyFilters();

    this.isInternal = this.getColumnFilterValue('Internal') === 'TRUE';
    this.isVulnerabilities =
      this.getColumnFilterValue('Vulnerabilities') === 'TRUE';
    this.isHasFix = this.getColumnFilterValue('HasFix') === 'TRUE';

    this.loadComponentData(null, null);

    const pageSize =
      this.userPreferenceService.getItemPerPageByModuleAndComponentName(
        'Project',
        'Components'
      );
    if (pageSize && this.defaultPageSize !== pageSize) {
      this.changePage({ pageSize });
    }
  }

  public ngOnDestroy(): void {
    this.filterStateService.saveFilter(
      this.isLicensePage ? 'licenseComponents' : 'components',
      this.columnsFilter
    );
  }

  public openPullRequestLink(link: string): void {
    window.open(link, '_blank');
  }

  public fixVersion(componentData): void {
    this.isFix = true;
    const modalRef: NgbModalRef = this.modalService.open(
      FixComponentDialogComponent,
      {
        keyboard: false,
      }
    );
    modalRef.componentInstance.scanId = this.scanId;
    modalRef.componentInstance.newVersion = this.newVersion;
    modalRef.componentInstance.oldVersion = componentData?.node?.version;
    modalRef.componentInstance.componentId = componentData?.node?.componentId;
    modalRef.componentInstance.name =
      componentData.node && componentData.node.group
        ? componentData.node.group +
          ':' +
          componentData.node.name +
          '@' +
          componentData.node.version
        : componentData.node.name + '@' + componentData.node.version;

    modalRef.componentInstance.fixResultSubject.subscribe((data: boolean) => {
      if (data) {
        this.getPrLink(componentData.node.componentId);
      }
    });
  }

  // While any changes occurred in page
  changePage(pageInfo) {
    if (this.isDisablePaggination) {
      return;
    }
    this.isDisablePaggination = true;

    if (this.defaultPageSize.toString() !== pageInfo.pageSize.toString()) {
      // page size changed...
      this.defaultPageSize = pageInfo.pageSize;
      //Setting item per page into session..
      this.userPreferenceService.settingUserPreference('Project', null, null, {
        componentName: 'Components',
        value: pageInfo.pageSize,
      });
      this.paginator?.firstPage();
      // API Call
      this.loadComponentData(
        Number(
          this.userPreferenceService.getItemPerPageByModuleAndComponentName(
            'Project',
            'Components'
          )
        ),
        undefined,
        undefined,
        undefined
      );
    } else {
      // Next and Previous changed
      if (pageInfo.pageIndex > pageInfo.previousPageIndex) {
        // call with after...
        if (
          !!this.componentDetails.components.pageInfo &&
          this.componentDetails.components.pageInfo['hasNextPage']
        ) {
          this.loadComponentData(
            Number(
              this.userPreferenceService.getItemPerPageByModuleAndComponentName(
                'Project',
                'Components'
              )
            ),
            undefined,
            this.componentDetails.components.pageInfo['endCursor'],
            undefined
          );
        }
      } else {
        // call with before..
        if (
          !!this.componentDetails.components.pageInfo &&
          this.componentDetails.components.pageInfo['hasPreviousPage']
        ) {
          this.loadComponentData(
            undefined,
            Number(
              this.userPreferenceService.getItemPerPageByModuleAndComponentName(
                'Project',
                'Components'
              )
            ),
            undefined,
            this.componentDetails.components.pageInfo['startCursor']
          );
        }
      }
    }
  }

  uniqData(edges) {
    return _.uniqBy(edges || [], 'node.vulnerabilityAlias');
  }

  /** Sort components */
  private sortComponents() {
    this.componentDetails.components.edges.sort(
      (a: ScanComponentEdge, b: ScanComponentEdge) => {
        const aName = this.getComponentName(a);
        const bName = this.getComponentName(b);

        return aName.localeCompare(bName);
      }
    );
  }

  // Loading Component data after paggination for scan tab.
  private loadComponentData(
    first,
    last,
    endCursor = undefined,
    startCursor = undefined
  ) {
    let component$;
    this.isDisablePaggination = true;
    if (this.isLicensePage) {
      component$ = this.projectService
        .getLicenseComponents(
          this.licenseId,
          this.licenseDiscovery,
          this.licenseOrigin,
          this.scanId,
          first || 25,
          last,
          endCursor,
          startCursor,
          this.makeFilterMapForService()
        )
        .pipe(
          map((result) => ({
            components: result.data?.scanLicense?.scanComponents,
            isScmActionsAvailableForScan: true,
            scanId: this.scanId,
          }))
        );
    } else {
      component$ = this.projectService
        .getScanComponents(
          this.scanId,
          this.makeFilterMapForService(),
          Number(
            this.userPreferenceService.getItemPerPageByModuleAndComponentName(
              'Project',
              'Components'
            )
          ),
          last,
          endCursor,
          startCursor,
          this.isShowCompositeData
        )
        .pipe(
          map((result: ApolloQueryResult<ScanQuery>) => ({
            components: result.data?.scan?.components,
            isScmActionsAvailableForScan:
              result.data?.scan?.isScmActionsAvailableForScan,
            scanId: result.data?.scan?.scanId,
          })),
          catchError((error) => of(EMPTY))
        );
    }

    component$.subscribe(
      (component: Scan) => {
        this.componentDetails = component as any;
        this.isScmActionsAvailableForScan =
          component.isScmActionsAvailableForScan;
        this.showAlert = !this.isScmActionsAvailableForScan;
        this.setupVelnerabilitiesStats();
        this.sortComponents();
        this.loading = false;
        this.isDisablePaggination = false;
      },
      (error) => {
        this.isDisablePaggination = false;
        console.error(error);
      }
    );
  }

  // goto detail Page
  gotoDetails(cId) {
    if (this.isFix) {
      this.isFix = false;
      return;
    }
    const entityId = this.route.snapshot.paramMap.get('entityId');
    const projectId = this.route.snapshot.paramMap.get('projectId');
    const url = this.isShowCompositeData
      ? 'security/entity/' +
        entityId +
        '/project/' +
        projectId +
        '/scan/' +
        this.scanId +
        '/component/' +
        btoa(cId) +
        '/isComposite/' +
        this.isShowCompositeData
      : 'security/entity/' +
        entityId +
        '/project/' +
        projectId +
        '/scan/' +
        this.scanId +
        '/component/' +
        btoa(cId);
    this.router.navigate([decodeURIComponent(url)]);
  }

  /**
   * Filter by columm
   *
   * @param column column name
   * @param event input event
   * @param idElement element ID
   */
  onFilterColumn(column: string, event: any, idElement: string = '') {
    let value = event; // .target as HTMLInputElement | HTMLSelectElement;

    if (column === 'Internal') {
      this.isInternal = !this.isInternal;
      value = this.isInternal ? 'TRUE' : '';
    }

    if (column === 'Vulnerabilities') {
      this.isVulnerabilities = !this.isVulnerabilities;
      value = this.isVulnerabilities ? 'TRUE' : '';
    }

    if (column === 'HasFix') {
      this.isHasFix = !this.isHasFix;
      value = this.isHasFix ? 'TRUE' : '';
    }

    if (value.length === 0) {
      this.columnsFilter.delete(column);
    } else {
      this.columnsFilter.set(column, value);
    }
    this.filtered = this.hasAnyFilters();

    this.filterStateService.saveFilter(
      this.isLicensePage ? 'licenseComponents' : 'components',
      this.columnsFilter
    );
    this.isDisablePaggination = true;
    this.paginator?.firstPage();
    this.loading = true;

    this.loadComponentData(null, null);
    // const scnObj = this.projectService
    //   .getScanComponents(
    //     this.scanId,
    //     this.makeFilterMapForService(),
    //     Number(
    //       this.userPreferenceService.getItemPerPageByModuleAndComponentName(
    //         'Project',
    //         'Components'
    //       )
    //     ),
    //     undefined,
    //     undefined,
    //     undefined,
    //     this.isShowCompositeData
    //   )
    //   .pipe(
    //     map((result: ApolloQueryResult<ScanQuery>) => result.data.scan),
    //     switchMap((res: Scan) => of(res))
    //   );

    // scnObj.subscribe((component: Scan) => {
    //   this.loading = false;
    //   this.componentDetails = component as any;
    //   this.isDisablePaggination = false;
    //   this.setupVelnerabilitiesStats();
    //   if (!!idElement && idElement !== '') {
    //     this.coreHelperService.setFocusOnElement(idElement);
    //   }
    // });
  }

  getColumnFilterValue(key: string): string {
    const value = this.columnsFilter.get(key);

    if (!value) {
      if (
        key === 'Location' ||
        key === 'Type' ||
        key === 'Discovery' ||
        key === 'cvss3Search' ||
        key === 'epssSearch' ||
        key === 'depthSearch' ||
        key === 'CVSS3' ||
        key === 'EPSS' ||
        key === 'MinDepth'
      ) {
        return 'ALL';
      } else {
        return '';
      }
    } else {
      return value;
    }
  }

  getTootltipValue(key) {
    return this.getColumnFilterValue(key) !== 'ALL'
      ? this.getColumnFilterValue(key)
      : '';
  }

  gotoLicense(selectedData) {
    const modalRef = this.modalService.open(LicenseDialogComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.selectedLicenseDetail = {
      name: selectedData.name,
      licensesList: selectedData.licenses['edges'],
    };
  }

  getComponentName(componentData) {
    return !!componentData.node && componentData.node.group
      ? componentData.node.group +
          ':' +
          componentData.node.name +
          '@' +
          componentData.node.version
      : componentData.node.name + '@' + componentData.node.version;
  }

  getDiscoverIn(str) {
    let val = '';
    switch (str) {
      case 'DEPENDENCY_FILE':
        val = 'DEPENDENCY FILE';
        break;
      case 'DRIVE':
        val = 'DRIVE';
        break;
      case 'STATIC_REF':
        val = 'STATIC REF';
        break;
      default:
        break;
    }
    return val;
  }

  getMaxServity(vulnerabilities) {
    const groupByValue = _.chain(vulnerabilities.edges)
      .groupBy('node.severity')
      .map((value, key) => ({ key, value }))
      .value();
    if (groupByValue.length >= 1) {
      return this.checkMaxANdReturnKeyhelper(groupByValue);
    } else {
      return '';
    }
  }

  public clearFilters(event: Event): void {
    this.columnsFilter = new Map();
    this.isVulnerabilities = false;
    this.isInternal = false;
    this.isHasFix = false;
    this.onFilterColumn('', event);
  }

  private getPrLink(componentId: string): void {
    this.scanComponentService
      .getComponentAutofixPullRequest(
        componentId,
        this.isShowCompositeData,
        this.route.snapshot.paramMap.get('projectId'),
        this.componentDetails.scanId
      )
      .subscribe((data) => {
        this.componentDetails.components.edges = [
          ...this.componentDetails.components.edges.map(
            (component: ScanComponentEdge) => {
              if (component.node.componentId === componentId) {
                component.node.autofixPullRequest =
                  data.data.scanComponent.autofixPullRequest;
              }
              return component;
            }
          ),
        ];
      });
  }

  private hasAnyFilters(): boolean {
    let result: boolean = false;
    this.columnsFilter.forEach((value, key) => {
      switch (key) {
        case 'Name':
        case 'Internal':
        case 'Vulnerabilities':
        case 'HasFix':
          result = result || !!value;
          break;
        case 'Type':
        case 'Discovery':
        case 'Location':
        case 'MinDepth':
        case 'cvss3Search':
        case 'epssSearch':
        case 'depthSearch':
          result = result || (value && value !== 'ALL');
          break;
        default:
          break;
      }
    });
    return result;
  }

  private checkMaxANdReturnKeyhelper(groupByValue) {
    const val = groupByValue.reduce((max, obj) =>
      max.value.length >= obj.value.length ? max : obj
    );
    const critical = groupByValue.find((a) => a.key === 'CRITICAL');
    const high = groupByValue.find((a) => a.key === 'HIGH');
    const medium = groupByValue.find((a) => a.key === 'MEDIUM');
    const low = groupByValue.find((a) => a.key === 'LOW');
    const info = groupByValue.find((a) => a.key === 'INFO');

    if (!!critical) {
      if (critical.value.length >= val.value.length) {
        return critical.key;
      }
    }
    if (!!high) {
      if (high.value.length >= val.value.length) {
        return high.key;
      }
    }
    if (!!medium) {
      if (medium.value.length >= val.value.length) {
        return medium.key;
      }
    }

    if (!!low) {
      if (low.value.length >= val.value.length) {
        return low.key;
      }
    }
    if (!!info) {
      if (info.value.length >= val.value.length) {
        return info.key;
      }
    } else {
      return '';
    }
  }

  private makeFilterMapForService(): string {
    let compString = '';
    this.columnsFilter.forEach((val, key) => {
      compString += key + ':' + val + ',';
    });
    return compString;
  }

  private setupVelnerabilitiesStats(): void {
    if (
      this.componentDetails.components &&
      this.componentDetails.components.edges
    ) {
      this.componentDetails.components.edges.map(
        (element: ScanComponentEdge) => {
          const vulnerabilitiesSeveretyTotal = {
            ...this.DefaultVulnerabilitiesSeveretyTotal,
          };
          element.node.vulnerabilities.edges.forEach(
            (vulnerability: VulnerabilityEdge) => {
              if (vulnerability.node?.severity) {
                vulnerabilitiesSeveretyTotal[vulnerability.node.severity] += 1;
              }
            }
          );
          element.node.vulnerabilities.edges['vulnerabilitiesSeveretyTotal'] =
            vulnerabilitiesSeveretyTotal;
          return element;
        }
      );
    }
  }
  openVulnerabilitiesInfoDialogComponent(
    componentData: ScanComponent,
    event: MouseEvent
  ) {
    event.stopPropagation();
    const modalRef: NgbModalRef = this.modalService.open(
      ComponentVulnerabilitiesInfoNewDialogComponent,
      {
        keyboard: false,
        size: 'lg',
        windowClass:
          'vulnerabilities-detail-modal ' +
          this.getMaxSeveretyVuln(
            componentData.vulnerabilities
          ).toLocaleLowerCase(),
      }
    );
    const entityId = this.route.snapshot.paramMap.get('entityId');
    const projectId = this.route.snapshot.paramMap.get('projectId');
    modalRef.componentInstance.severity = this.getMaxSeveretyVuln(
      componentData.vulnerabilities
    );
    modalRef.componentInstance.scanId = this.scanId;
    modalRef.componentInstance.entityId = entityId;
    modalRef.componentInstance.projectId = projectId;
    modalRef.componentInstance.newVersion = this.newVersion;
    modalRef.componentInstance.componentData = componentData;
  }

  private getMaxSeveretyVuln(vulnerabilities: ScanVulnerabilityEdge): string {
    if (vulnerabilities.edges.length === 0) {
      return null;
    }
    if (vulnerabilities.edges.length === 1) {
      return vulnerabilities.edges[0].node.severity;
    }

    const severities = vulnerabilities.edges.map((edge) => edge.node.severity);
    let index: number;
    severities.forEach((item: string) => {
      if (index === undefined) {
        index = vulnerabilitiesSortOrder.indexOf(item);
      } else {
        const itIndex = vulnerabilitiesSortOrder.indexOf(item);
        index = index < itIndex ? index : itIndex;
      }
    });
    return vulnerabilitiesSortOrder[index];
  }
}
