<app-ui-modal #licenseAddDialog>
  <!-- HEADER ================================================================================================= -->
  <div class="app-modal-header">
    <h5 class="modal-title">{{ editing ? 'Edit ' : 'Add ' }} Component</h5>
    <button
      type="button"
      class="close basic-close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="licenseAddDialog.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- BODY ================================================================================================= -->
  <div class="app-modal-body">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="info-cols"><small>Component name</small></div>
        <input
          [(ngModel)]="componentToAdd.name"
          type="text"
          #nameModel="ngModel"
          required
          [pattern]="'^[^ ]*$'"
          class="form-control compliance-dashboard-add-component-dialog-input"
        />
        <div
          class="invalid-form"
          *ngIf="nameModel.errors && (nameModel.dirty || nameModel.touched)"
        >
          <small *ngIf="nameModel.errors?.required">Name is required.</small>
          <small *ngIf="nameModel.errors?.pattern"
            >Spaces are not allowed.</small
          >
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="info-cols"><small>Component namespace</small></div>
        <input
          [(ngModel)]="componentToAdd.namespace"
          type="text"
          #namespaceModel="ngModel"
          required
          class="form-control compliance-dashboard-add-component-dialog-input"
        />
        <div
          class="invalid-form"
          *ngIf="
            namespaceModel.errors &&
            (namespaceModel.dirty || namespaceModel.touched)
          "
        >
          <small
            *ngIf="namespaceModel.errors && namespaceModel.errors?.required"
            >Namespace is required.</small
          >
        </div>
      </div>

      <div class="col-sm-6">
        <div class="info-cols"><small>Component version</small></div>
        <input
          [(ngModel)]="componentToAdd.version"
          type="text"
          #versionModel="ngModel"
          required
          class="form-control compliance-dashboard-add-component-dialog-input"
        />
        <div
          class="invalid-form"
          *ngIf="
            versionModel.errors && (versionModel.dirty || versionModel.touched)
          "
        >
          <small *ngIf="versionModel.errors && versionModel.errors?.required"
            >Version is required.</small
          >
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <!-- RELEASE DATE -->
      <div class="col-sm-6">
        <div class="info-cols"><small>Release Date</small></div>
        <div class="input-group">
          <input
            class="form-control compliance-dashboard-add-component-dialog-input"
            placeholder="Select a date"
            name="dp"
            [(ngModel)]="componentToAdd.releaseDate"
            ngbDatepicker
            #d="ngbDatepicker"
            #dateModel="ngModel"
            [minDate]="minDate"
            [maxDate]="maxDate"
          />
          <button
            class="btn btn-outline-secondary"
            (click)="d.toggle()"
            type="button"
          >
            <i class="fa-solid fa-calendar-days"></i>
          </button>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="info-cols"><small>Purl type</small></div>
        <div>
          <select
            name="purlType"
            class="form-control custom-select"
            [(ngModel)]="componentToAdd.purlType"
            #purlTypeModel="ngModel"
            required
          >
            <option [ngValue]="type.id" *ngFor="let type of purTypes">
              {{ type.name }}
            </option>
          </select>
          <div
            class="invalid-form"
            *ngIf="
              purlTypeModel.errors &&
              (purlTypeModel.dirty || purlTypeModel.touched)
            "
          >
            <small
              *ngIf="purlTypeModel.errors && purlTypeModel.errors?.required"
              >Purl type is required.</small
            >
          </div>
        </div>
      </div>
    </div>

    <!-- SEARCH -->
    <div class="mb-4">
      <div class="info-cols">
        <small>Search license to add to component</small>
      </div>
      <input
        id="typeahead-format"
        type="text"
        class="form-control"
        placeholder="Type name of existing license"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [editable]="false"
        (selectItem)="setLicenseProperties($event)"
      />
    </div>

    <div class="row mb-2" *ngFor="let license of componentToAdd.licenses">
      <!-- NAME -->
      <div class="col-sm-6">
        <div class="info-cols"><small>License name</small></div>
        <input
          [(ngModel)]="license.name"
          type="text"
          class="form-control compliance-dashboard-add-component-dialog-input"
        />
      </div>

      <!-- CATEGORY -->
      <div class="col-sm-5">
        <div class="info-cols"><small>Category</small></div>
        <div>
          <select
            name="typeFilter"
            class="form-control custom-select"
            [(ngModel)]="license.category"
            #licenseCategoryModel="ngModel"
            required
          >
            <option value=""></option>
            <option [value]="category.id" *ngFor="let category of categories">
              {{ category.name }}
            </option>
          </select>
          <div
            class="invalid-form"
            *ngIf="
              licenseCategoryModel.errors &&
              (licenseCategoryModel.dirty || licenseCategoryModel.touched)
            "
          >
            <small
              *ngIf="
                licenseCategoryModel.errors &&
                licenseCategoryModel.errors?.required
              "
              >License category name is required.</small
            >
          </div>
        </div>
      </div>

      <div class="col-sm-1">
        <i class="fa fa-trash" (click)="deleteLicense(license.licenseId)"></i>
      </div>
    </div>

    <!-- TEXT -->
    <div class="info-cols"><small>Copyright text</small></div>
    <textarea
      [(ngModel)]="componentToAdd.copyright"
      type="text"
      class="form-control compliance-dashboard-add-component-dialog-input"
    ></textarea>

    <div
      class="col-sm-6"
      style="
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding-left: 2px;
      "
    >
      <div class="info-cols"><small>Internal</small></div>
      <input [(ngModel)]="componentToAdd.isInternal" type="checkbox" />
    </div>
  </div>

  <!-- FOOTER ================================================================================================= -->
  <div class="app-modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="licenseAddDialog.hide()"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary ml-2"
      (click)="saveComponent()"
    >
      {{ editing ? 'Save ' : 'Add ' }} Component
    </button>
  </div>
</app-ui-modal>
