import { ScanAsset, ScanAssetMatch } from '@app/models';
import { GraphQLPage } from '@app/models/paging';

export class LoadAssetsList {
  static type = '[ResultsAndReview] LoadComponents';

  constructor(
    public entityId: string,
    public scanId: string,
    public assetPath: string
  ) {}
}

export class LoadNextPage {
  static type = '[ResultsAndReview] LoadNextPage';
}

export class LoadLicenses {
  static type = '[ResultsAndReview] LoadLicenses';
  constructor(
    public entityId: string,
    public scanId: string,
    public assetPath: string,
    public showCardLicenses: boolean
  ) {}
}

export class LoadAssetsMetricsForPath {
  static type = '[ResultsAndReview] LoadAssetsMetricsForPath';
  constructor(
    public entityId: string,
    public scanId: string,
    public assetPath: string
  ) {}
}

export class ChangeView {
  static type = '[ResultsAndReview] ChangeView';

  constructor(public view: number) {}
}

export class ChangeFiltersView {
  static type = '[ResultsAndReview] ChangeFiltersView';

  constructor() {}
}

export class ResetFilters {
  static type = '[ResultsAndReview] ResetFilters';

  constructor(public logout: boolean = false) {}
}

export class ResetLicenseFilterAssets {
  static type = '[ResultsAndReview] ResetLicenseFilterAssets';

  constructor() {}
}

export class ResetLicenseFilterAssetDetails {
  static type = '[ResultsAndReview] ResetLicenseFilterAssetDetails';

  constructor() {}
}

export class ResetFiltersForDetails {
  static type = '[ResultsAndReview] ResetFiltersForDetails';

  constructor() {}
}

export class ChangeFilterValue {
  static type = '[ResultsAndReview] ChangeFilterValue';

  constructor(
    public key: string,
    public value: any,
    public licenseName?: string
  ) {}
}

export class ChangeSeveralFilterValue {
  static type = '[ResultsAndReview] ChangeSeveralFilterValue';

  constructor(public filter: any) {}
}
export class ChangeFilterAssetDetailsValue {
  static type = '[ResultsAndReview] ChangeFilterAssetDetailsValue';

  constructor(
    public key: string,
    public value: any,
    public licenseName?: string
  ) {}
}

export class LoadAssetCardsOrList {
  static type = '[ResultsAndReview] LoadAssetCardsOrList';

  constructor() {}
}

export class ReviewList {
  static type = '[ResultsAndReview] ReviewList';

  constructor(public actionType: 'approve' | 'ignore' | 'reject' | 'revert') {}
}

export class RemoveReviewedAsset {
  static type = '[ResultsAndReview] RemoveReviewedAsset';

  constructor(public assetId: string) {}
}

export class ResultAndReviewLoading {
  static type = '[ResultsAndReview] ResultAndReviewLoading';

  constructor(public loading: boolean) {}
}

export class ResetScanAssetMatch {
  static type = '[ResultsAndReview] ResetScanAssetMatch';

  constructor(
    public scanAssetMatch: ScanAssetMatch,
    public scanAsset: ScanAsset = null
  ) {}
}

export class MarkMatchAsRejected {
  static type = '[ResultsAndReview] MarkMatchAsRejected';

  constructor(
    public scanAssetMatch: ScanAssetMatch,
    public scanAsset: ScanAsset = null
  ) {}
}

export class MarkMatchAsReviewed {
  static type = '[ResultsAndReview] MarkMatchAsReviewed';

  constructor(
    public scanAssetMatch: ScanAssetMatch,
    public scanAsset: ScanAsset = null
  ) {}
}

export class SetIgnoredScanAssetMatch {
  static type = '[ResultsAndReview] SetIgnoredScanAssetMatch';

  constructor(
    public scanAssetMatch: ScanAssetMatch,
    public scanAsset: ScanAsset = null
  ) {}
}
