import { Injectable } from '@angular/core';
import { GraphQLPage } from '@app/models/paging';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable()
export class UtilsService {
  constructor(
    private clipboard: Clipboard,
    private toastrService: ToastrService
  ) {}

  copyTextToClipboard(text: string, message?: string) {
    this.clipboard.copy(text);

    if (message !== undefined) {
      this.toastrService.info(message);
    }
  }

  getAPIParameters(filter, graphQLPage?: GraphQLPage) {
    const filterArg = !!filter
      ? 'filter: "' + 'auditFilters:true,' + filter + '"'
      : '';
    const firstArg = !!graphQLPage?.first ? `first: ${graphQLPage?.first}` : '';
    const lastArg = !!graphQLPage?.last ? `last: ${graphQLPage?.last}` : '';
    const afterArg = graphQLPage?.after
      ? `, after: "${graphQLPage?.after}"`
      : '';
    const beforeArg = graphQLPage?.before
      ? `, before: "${graphQLPage?.before}"`
      : '';

    return { filterArg, firstArg, lastArg, afterArg, beforeArg };
  }

  sumFields(obj: any): number {
    let sumResult: number = 0;
    if (!obj) {
      return sumResult;
    }
    Object.keys(obj).forEach((key: string) => {
      if (typeof obj[key] === 'number') {
        sumResult += obj[key];
      }
    });
    return sumResult;
  }

  public filterToString(filter): string {
    if (!filter) {
      return '';
    }
    let filterString: string = '';
    Object.keys(filter).forEach((key: string) => {
      if (
        filter[key] !== undefined &&
        filter[key] !== null &&
        filter[key] !== ''
      ) {
        filterString += key + ':' + filter[key] + '|';
      }
    });
    return filterString;
  }
}
