import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { Repository, ScanAsset, SimmMatch } from '@app/models';
import { SimmService } from '@app/services/simm.service';
import { RepositoryService } from '@app/services/repository.service';
import { ProjectService } from '@app/services/project.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import * as $ from 'jquery';

@Component({
  selector: 'app-simm',
  templateUrl: './simm.component.html',
  styleUrls: ['./simm.component.scss'],
})
export class SimmComponent implements OnChanges {
  @Input() sourceAsset: ScanAsset;
  @Input() matchAsset: ScanAsset;
  @Input() stateOpen = true;
  @Input() scanId = '';

  matches: SimmMatch[];
  currentMatchIndex = -1;

  // sourceLineHighlights = '10-15,25-30';
  // matchLineHighlights = '10-15,25-30';
  sourceLineHighlights = '';
  matchLineHighlights = '';
  sourceLineCount = 0;
  matchLineCount = 0;

  @ViewChildren('sourceScroll', { read: ElementRef })
  sourceScroll: QueryList<ElementRef>;
  @ViewChildren('matchScroll', { read: ElementRef })
  matchScroll: QueryList<ElementRef>;

  public sourceLoaded: boolean = false;
  public matchLoaded: boolean = false;
  public projectIsUploaded: boolean;
  public sourceSubscr: Subscription;

  constructor(
    private simmService: SimmService,
    private repositoryService: RepositoryService,
    private projectService: ProjectService,
    private toastr: ToastrService
  ) {}

  loadData(test?: boolean) {
    if (test) {
      this.updateHighlightsTest();
      this.countLines();
    } else {
      if (this.matchAsset && this.sourceAsset) {
        if (
          this.sourceAsset.content !== undefined &&
          this.sourceAsset.content !== null
        ) {
          this.simmService
            .compare(
              this.sourceAsset.content,
              this.matchAsset.content,
              this.sourceAsset.scanAssetId,
              this.sourceAsset.scanId
            )
            .subscribe(
              (data) => {
                this.matches = data.data.simmCompare;
                this.currentMatchIndex = -1;
                this.updateHighlights();
                this.countLines();
                this.setupPreElementWidth();
              },
              (error) => {
                console.error('SimmComponent', error);
              }
            );
        }
      }
    }
  }

  ngOnChanges() {
    this.loadData();
  }

  updateHighlightsTest() {
    this.sourceLineHighlights = '2,98,114,159';
    this.matchLineHighlights = '2,97,99,100,160';
  }

  applyPatchTest() {
    this.simmService.applyPatch(this.scanId).subscribe(
      (data) => {
        this.toastr.success('The patch was applied successfully');
      },
      (error) => {
        console.error('SimmComponent', error);
      }
    );
  }

  openTestLink(): void {
    window.open('https://osv.dev/vulnerability/GHSA-x637-x8p3-5p22', '_blank');
  }

  updateHighlights() {
    this.sourceLineHighlights = '';
    this.matchLineHighlights = '';
    for (const match of this.matches) {
      if (this.sourceLineHighlights.length > 0) {
        this.sourceLineHighlights += ',';
        this.matchLineHighlights += ',';
      }
      this.sourceLineHighlights += match.leftStart + '-' + match.leftEnd;
      this.matchLineHighlights += match.rightStart + '-' + match.rightEnd;
    }
  }

  countLines() {
    const regex = /\n/g;
    this.sourceLineCount = (
      (this.sourceAsset?.content || '').match(regex) || []
    ).length;
    this.matchLineCount = (
      (this.matchAsset?.content || '').match(regex) || []
    ).length;

    if (this.sourceLineCount > 0) {
      this.sourceLineCount++;
    } else if (
      this.sourceAsset?.content !== undefined &&
      this.sourceAsset?.content !== ''
    ) {
      this.sourceLineCount = 1;
    }
    /*if (this.matchLineCount > 0) {
            this.matchLineCount++;
        }
        else if (this.matchAsset.content !== undefined && this.matchAsset.content !== '') {
            this.matchLineCount = 1;
        }*/
  }

  @HostListener('window:keydown', ['$event'])
  KeyDown(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.close();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  open() {
    this.stateOpen = true;
  }

  /**
   *
   * @param scanRepository
   * @param sourceAsset
   * @param matchAsset
   */
  openWithParams(
    scanRepository: Repository,
    sourceAsset: ScanAsset,
    matchAsset: ScanAsset,
    simmtool
  ) {
    this.sourceAsset = _.cloneDeep(sourceAsset);
    this.matchAsset = _.cloneDeep(matchAsset);
    // const authorizedClients: Array<AuthorizedClients> =
    //   this.authenticationService.getFromStorageBasedEnv('authorizedClients');
    // const accessToken = scanRepository
    //   ? this.authenticationService.getUserRepoAccountAccessToken(
    //       authorizedClients,
    //       scanRepository
    //     )
    //   : '';

    // then load for match asset
    this.repositoryService
      .fetchAsset(this.matchAsset.assetRepositoryUrl.data)
      .subscribe(
        (response2) => {
          if (response2.content) {
            this.matchAsset.content = atob(response2.content);
          } else {
            // bitbucket return raw file only, it response hasn't node 'content'
            this.matchAsset.content = atob(response2);
          }

          simmtool.matchLoaded = true;
          simmtool.open();

          if (this.sourceAsset.assetRepositoryUrl.data) {
            this.projectService
              .getAssetContent(
                this.sourceAsset.assetRepositoryUrl.data,
                scanRepository?.['registrationId']
              )
              .subscribe(
                (res) => {
                  this.sourceAsset.content = res.data.assetContent.data;

                  simmtool.sourceLoaded = true;
                  simmtool.loadData();
                },
                () => {
                  simmtool.sourceLoaded = true;
                }
              );
          } else {
            simmtool.sourceLoaded = true;
          }

          // if (accessToken) {
          //   this.fetchAuthenticatedAsset(accessToken).subscribe((response) => {
          //     if (response.content) {
          //       this.sourceAsset.content = atob(response.content);
          //     } else {
          //       // bitbucket return raw file only, it response hasn't node 'content'
          //       this.sourceAsset.content = atob(response);
          //     }
          //     this.runSimmServiceSettings();
          //   });

          //   console.log(this.sourceAsset.assetRepositoryUrl.data);
          // } else {
          //   if (this.sourceAsset.assetRepositoryUrl.data) {
          //     this.projectService
          //       .getAssetContent(this.sourceAsset.assetRepositoryUrl.data, null)
          //       .subscribe(
          //         (res) => {
          //           this.sourceAsset.content = res.data.assetContent.data;

          //           simmtool.sourceLoaded = true;
          //           simmtool.loadData();
          //           this.runSimmServiceSettings();
          //         },
          //         () => {
          //           simmtool.sourceLoaded = true;
          //         }
          //       );
          //   } else {
          //     simmtool.sourceLoaded = true;
          //     this.projectIsUploaded = true;
          //   }
          // }
        },
        (error) => {
          console.error(error);
          simmtool.sourceLoaded = true;
          simmtool.matchLoaded = true;
        }
      );
  }

  /**
   *
   * @param scanRepository
   * @param sourceAsset
   * @param matchAsset
   */
  openOnlySource(scanRepository: Repository, sourceAsset: ScanAsset, simmtool) {
    this.sourceAsset = sourceAsset;
    this.matchAsset = null;
    if (this.sourceAsset.assetRepositoryUrl.data) {
      this.sourceSubscr = this.projectService
        .getAssetContent(
          this.sourceAsset.assetRepositoryUrl.data,
          scanRepository?.['registrationId']
        )
        .subscribe(
          (res) => {
            this.sourceAsset.content = res.data.assetContent.data;
            simmtool.open();
            simmtool.sourceLoaded = true;
          },
          (error) => {
            console.error(error);
            simmtool.sourceLoaded = true;
          }
        );
    }
    // });
  }

  runSimmServiceSettings(): void {
    this.simmService
      .compare(
        this.sourceAsset.content,
        this.matchAsset.content,
        this.sourceAsset.scanAssetId,
        this.sourceAsset.scanId
      )
      .subscribe(
        (data) => {
          this.matches = data.data.simmCompare;
          this.currentMatchIndex = -1;
          this.updateHighlights();
          this.countLines();
          // show overlay
          this.stateOpen = true;
        },
        (error) => {
          console.error('SimmComponent', error);
        }
      );
  }

  fetchAuthenticatedAsset(accessToken: string) {
    return this.repositoryService.fetchAuthenticatedAsset(
      this.sourceAsset.assetRepositoryUrl.data,
      accessToken
    );
  }

  close() {
    if (this.sourceSubscr) {
      this.sourceSubscr.unsubscribe();
    }
    this.stateOpen = false;
    this.sourceAsset = null;
    this.matchAsset = null;
    this.sourceLineHighlights = '';
    this.matchLineHighlights = '';
    this.sourceLineCount = 0;
    this.matchLineCount = 0;
  }

  nextMatch() {
    this.currentMatchIndex++;
    if (this.currentMatchIndex >= this.matches.length) {
      this.currentMatchIndex = this.matches.length - 1;
    }
    this.scroll();
  }

  prevMatch() {
    this.currentMatchIndex--;
    if (this.currentMatchIndex < 0) {
      this.currentMatchIndex = 0;
    }
    this.scroll();
  }

  scroll() {
    this.scrollSide(this.sourceScroll.first.nativeElement, 'left');
    this.scrollSide(this.matchScroll.first.nativeElement, 'right');
  }

  scrollSide(element, side) {
    const md = element.children[0];
    const eH = element.offsetHeight;
    const height = md.offsetHeight - 28;
    const match = this.matches[this.currentMatchIndex];
    const k =
      side === 'left'
        ? match.leftStart / this.sourceLineCount
        : match.rightStart / this.matchLineCount;
    const lh =
      height / (side === 'left' ? this.sourceLineCount : this.matchLineCount);
    const scrollTo = height /*- eH*/ /*- 60*/ * k + 14 - lh;
    element.scroll(0, scrollTo);
  }

  private setupPreElementWidth(): void {
    setTimeout(() => {
      if ($('pre')[1]) {
        $($('pre')[1]).width($($('pre')[1].childNodes[0]).width() + 'px');
      }
      if ($('pre')[2]) {
        $($('pre')[2]).width($($('pre')[2].childNodes[0]).width() + 'px');
      }
    }, 100);
  }
}
